import { animate, state, style, transition, trigger } from '@angular/animations';

export const revealTrigger = trigger('reveal', [
    state('void', style({ opacity: 0 })),
    state('*', style({ opacity: 1 })),
    transition(':enter', [animate('.4s ease-in-out')]),
]);

export const revealWithScaleTrigger = trigger('revealWithScale', [
    state('void', style({ opacity: 0, transform: 'scaleY(.95)', transformOrigin: 'top' })),
    state('*', style({ opacity: 1, transform: 'scaleY(1)' })),
    transition(':enter', [animate('.4s ease-in-out')]),
]);

export const showHideTrigger = trigger('showHide', [
    state('void', style({ opacity: 0, transform: 'scaleY(.95)', transformOrigin: 'top' })),
    state('*', style({ opacity: 1, transform: 'scaleY(1)' })),
    transition(':enter, :leave', [animate('.4s ease-in-out')]),
]);
