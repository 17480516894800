import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Input,
    Optional,
    SkipSelf,
    TemplateRef,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
} from '@angular/forms';
import { NzColor } from 'ng-zorro-antd/color-picker';
import { Globals } from 'src/app/core/services/globals';

@Component({
    selector: 'app-form-color-picker',
    templateUrl: './color-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerComponent),
            multi: true,
        },
    ],
})
export class ColorPickerComponent implements ControlValueAccessor, AfterViewInit {
    @Input() formControlName: string;
    @Input() formControl: UntypedFormControl;
    @Input() placeholder = '';
    @Input() info: string;
    @Input() errorTip: string | TemplateRef<{ $implicit: FormControl }> = 'This field is required';
    @Input() hasFeedback = false;

    value: string = null;

    propagateChange: (_: string) => void;
    propagateTouch: () => void;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        public globals: Globals,
    ) {}

    ngAfterViewInit(): void {
        if (this.controlContainer && !this.formControl) {
            setTimeout(() => {
                this.formControl = this.controlContainer.control.get(
                    this.formControlName,
                ) as UntypedFormControl;
            });
        }
    }

    onOpenChange(open: boolean): void {
        if (!open) {
            this.propagateTouch();
        }
    }

    registerOnChange(fn: (_: string) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    setValue(event: { color: NzColor; format: string }): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        this.value = event.color?.toHexString(false);
        this.propagateChange(this.value);
    }

    clear(): void {
        this.formControl.reset();
    }
}
