import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { authReducer } from 'src/app/store/auth/auth.reducers';
import { AuthState } from 'src/app/store/auth/auth.state';

export const rootReducer = {
    auth: authReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
    return localStorageSync({
        keys: [
            {
                auth: {
                    encrypt: (state: string) => btoa(state),
                    decrypt: (state: string): string => {
                        try {
                            return atob(state);
                        } catch {
                            return '';
                        }
                    },
                },
            },
        ],
        rehydrate: true,
    })(reducer);
}
export const metaReducers: Array<MetaReducer> = [localStorageSyncReducer];

export interface AppState {
    auth: AuthState;
}
