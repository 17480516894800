import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Input,
    Optional,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
} from '@angular/forms';
import { Globals } from 'src/app/core/services/globals';

export type AutocompleteOption = { label: string; value: unknown; badge?: string };

@Component({
    selector: 'app-form-rate',
    templateUrl: './rate.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RateComponent),
            multi: true,
        },
    ],
})
export class RateComponent implements ControlValueAccessor, AfterViewInit {
    @Input() formControlName: string;
    @Input() formControl: UntypedFormControl;
    @Input() allowClear = false;

    value: number;

    propagateChange: (_: number) => void;
    propagateTouch: () => void;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        public globals: Globals,
    ) {}

    ngAfterViewInit(): void {
        if (this.controlContainer && !this.formControl) {
            setTimeout(() => {
                this.formControl = this.controlContainer.control.get(
                    this.formControlName,
                ) as UntypedFormControl;
            });
        }
    }

    setValue(value: number): void {
        this.value = value;
        this.propagateChange(this.value);
    }

    registerOnChange(fn: (_: number) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: number): void {
        this.value = value;
    }
}
