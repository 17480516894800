import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditMarketDto,
    MarketQueryDto,
} from 'src/app/core/services/moveup-api/localization/dtos/market/market-request.dtos';
import { MarketResponseDto } from 'src/app/core/services/moveup-api/localization/dtos/market/market-response.dtos';
import { dtoToHttpParamsV2 } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class MarketService {
    baseUrl = `${environment.api.baseUrl}/admin/localization/markets`;

    constructor(private http: HttpClient) {}

    list(dto?: MarketQueryDto): Observable<MarketResponseDto[]> {
        return this.http.get<MarketResponseDto[]>(`${this.baseUrl}`, {
            ...(dto && { params: dtoToHttpParamsV2(dto) }),
        });
    }

    add(dto: AddEditMarketDto): Observable<MarketResponseDto> {
        return this.http.post<MarketResponseDto>(`${this.baseUrl}`, dto);
    }

    edit(id: string, dto: AddEditMarketDto): Observable<MarketResponseDto> {
        return this.http.put<MarketResponseDto>(`${this.baseUrl}/${id}`, dto);
    }
}
