<nz-form-item>
    <ng-content></ng-content>
    <nz-form-control
        *ngIf="formControl"
        [nzAutoTips]="globals.FORM_AUTO_TIPS"
        [nzValidateStatus]="formControl"
        [nzExtra]="info"
        [nzErrorTip]="errorTip"
        nz-tooltip
        [nzTooltipTitle]="tooltip"
        [nzTooltipPlacement]="tooltipPlacement"
        [nzTooltipColor]="tooltipColor"
    >
        <nz-select
            *ngIf="!groupOptions"
            [formControl]="formControl"
            [nzAllowClear]="true"
            [nzPlaceHolder]="placeholder"
            [nzShowSearch]="showSearch"
            [nzShowArrow]="true"
            [nzLoading]="isLoading"
            [compareWith]="compareFn"
            [nzSize]="size"
            [nzMode]="mode"
            [nzDisabled]="disabled"
            [nzAllowClear]="allowClear"
        >
            @for (option of options; track option) {
                <nz-option nzCustomContent [nzLabel]="option.label" [nzValue]="option.value">
                    @if (option.icon) {
                        <img
                            nz-image
                            width="20px"
                            height="20px"
                            [nzSrc]="option.icon"
                            [alt]="option.label"
                        />
                    }
                    {{ option.label }}
                    @if (option.badges && option.badges.length) {
                        <div>
                            <nz-space>
                                @for (badge of option.badges; track badge) {
                                    <nz-tag *nzSpaceItem [nzColor]="badge.color">
                                        {{ badge.text }}
                                    </nz-tag>
                                }
                            </nz-space>
                        </div>
                    }
                    @if (option.subLabel) {
                        <div>
                            <small>{{ option.subLabel }}</small>
                        </div>
                    }
                </nz-option>
            }
        </nz-select>
        <nz-select
            *ngIf="groupOptions"
            [formControl]="formControl"
            [nzAllowClear]="true"
            [nzPlaceHolder]="placeholder"
            [nzShowSearch]="showSearch"
            [nzShowArrow]="true"
            [nzLoading]="isLoading"
            [compareWith]="compareFn"
            [nzSize]="size"
            [nzMode]="mode"
            [nzDisabled]="disabled"
        >
            @for (group of groupOptions; track group) {
                <nz-option-group [nzLabel]="group.name">
                    @for (option of group.options; track option) {
                        <nz-option
                            nzCustomContent
                            [nzLabel]="option.label"
                            [nzValue]="option.value"
                        >
                            @if (option.icon) {
                                <img
                                    nz-image
                                    width="20px"
                                    height="20px"
                                    [nzSrc]="option.icon"
                                    [alt]="option.label"
                                />
                            }
                            {{ option.label }}
                            @if (option.badges && option.badges.length) {
                                <div>
                                    <nz-space>
                                        @for (badge of option.badges; track badge) {
                                            <nz-tag *nzSpaceItem [nzColor]="badge.color">
                                                {{ badge.text }}
                                            </nz-tag>
                                        }
                                    </nz-space>
                                </div>
                            }
                            @if (option.subLabel) {
                                <div>
                                    <small>{{ option.subLabel }}</small>
                                </div>
                            }
                        </nz-option>
                    }
                </nz-option-group>
            }
        </nz-select>
    </nz-form-control>
</nz-form-item>
