import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddUserDto,
    EditUserDto,
    UserResponseDto,
} from 'src/app/core/services/moveup-api/users/users/users.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsersService {
    baseUrl = `${environment.api.baseUrl}/admin/users/users`;

    constructor(private http: HttpClient) {}

    addUser(dto: AddUserDto): Observable<UserResponseDto> {
        return this.http.post<UserResponseDto>(`${this.baseUrl}`, dto);
    }

    editUser(userId: string, dto: EditUserDto): Observable<UserResponseDto> {
        return this.http.put<UserResponseDto>(`${this.baseUrl}/${userId}`, dto);
    }

    removeUser(userId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${userId}`);
    }

    getList(): Observable<UserResponseDto[]> {
        return this.http.get<UserResponseDto[]>(`${this.baseUrl}`);
    }

    getUser(userId: string): Observable<UserResponseDto> {
        return this.http.get<UserResponseDto>(`${this.baseUrl}/${userId}`);
    }

    emailExists(userId: string, email: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/email-exists/${userId}/${email}`);
    }
}
