<nz-alert
    nz-col
    nzSpan="24"
    [nzAction]="shortcodeActions"
    [nzMessage]="title"
    nzIcon="code"
    nzType="success"
    [nzDescription]="code"
>
    <ng-template #code>
        <code>{{ shortcode }}</code>
    </ng-template>
    <ng-template #shortcodeActions>
        <nz-space nzDirection="vertical">
            <button
                *nzSpaceItem
                nz-button
                nzSize="default"
                nzType="dashed"
                type="button"
                nzDanger
                (click)="copy(shortcode)"
            >
                <span nz-icon nzType="copy" nzTheme="outline"></span>
                Copy
            </button>
            <button
                *nzSpaceItem
                nz-button
                nzSize="default"
                nzType="dashed"
                type="button"
                nz-dropdown
                [nzDropdownMenu]="previewDropdown"
                nzTrigger="click"
            >
                <span nz-icon nzType="eye" nzTheme="outline"></span>
                Preview
                <span nz-icon nzType="down"></span>
            </button>
            <nz-dropdown-menu #previewDropdown="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item nzDanger (click)="preview(shortcode, true)">With sidebar</li>
                    <li nz-menu-item nzDanger (click)="preview(shortcode, false)">
                        Without sidebar
                    </li>
                </ul>
            </nz-dropdown-menu>
        </nz-space>
    </ng-template>
</nz-alert>
