import { ContinentService } from 'src/app/core/services/moveup-api/localization/continent/continent.service';
import { CountryService } from 'src/app/core/services/moveup-api/localization/country/country.service';
import { LanguageService } from 'src/app/core/services/moveup-api/localization/language/language.service';
import { MarketService } from 'src/app/core/services/moveup-api/localization/market/market.service';
import { RegionService } from 'src/app/core/services/moveup-api/localization/region/region.service';

export const MOVEUP_API_LOCALIZATION_SERVICES = [
    ContinentService,
    CountryService,
    RegionService,
    LanguageService,
    MarketService,
];
