import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddBatchTrackingMaterialDto,
    BookmakerIdDto,
    EditTrackingMaterialDto,
    TrackingMaterialActivityResponseDto,
    TrackingMaterialAdElementResponseDto,
    TrackingMaterialAddPidDto,
    TrackingMaterialCountryResponseDto,
    TrackingMaterialFiltersDto,
    TrackingMaterialPidResponseDto,
    TrackingMaterialResponseDto,
    TrackingMaterialUrlMatchingResponseDto,
    UpdateItemsArchiveDto,
    UpdateItemsStatusDto,
    WebsiteIdsAndMarketIdsResponseDto,
} from 'src/app/core/services/moveup-api/tracking-material/tracking-material.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParams } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class TrackingMaterialService {
    baseUrl = `${environment.api.baseUrl}/admin/tracking-material`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: TrackingMaterialFiltersDto,
    ): Observable<Pagination<TrackingMaterialResponseDto>> {
        return this.http.get<Pagination<TrackingMaterialResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
            { params: dtoToHttpParams(dto) },
        );
    }

    export(dto: TrackingMaterialFiltersDto): Observable<TrackingMaterialResponseDto[]> {
        return this.http.get<TrackingMaterialResponseDto[]>(`${this.baseUrl}/export`, {
            params: dtoToHttpParams(dto),
        });
    }

    add(dto: AddBatchTrackingMaterialDto): Observable<TrackingMaterialResponseDto> {
        return this.http.post<TrackingMaterialResponseDto>(`${this.baseUrl}`, dto);
    }

    edit(id: string, dto: EditTrackingMaterialDto): Observable<TrackingMaterialResponseDto> {
        return this.http.put<TrackingMaterialResponseDto>(`${this.baseUrl}/${id}`, dto);
    }

    archive(id: string): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/archive/${id}`, null);
    }

    unarchive(id: string): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/unarchive/${id}`, null);
    }

    addTrackingMaterialPid(
        dto: TrackingMaterialAddPidDto,
    ): Observable<TrackingMaterialPidResponseDto[]> {
        return this.http.post<TrackingMaterialPidResponseDto[]>(`${this.baseUrl}/pid`, dto);
    }

    getTrackingMaterial(id: string): Observable<TrackingMaterialResponseDto> {
        return this.http.get<TrackingMaterialResponseDto>(`${this.baseUrl}/${id}`);
    }

    getAdElementList(): Observable<TrackingMaterialAdElementResponseDto[]> {
        return this.http.get<TrackingMaterialAdElementResponseDto[]>(`${this.baseUrl}/ad-elements`);
    }

    getActivityList(): Observable<TrackingMaterialActivityResponseDto[]> {
        return this.http.get<TrackingMaterialActivityResponseDto[]>(`${this.baseUrl}/activities`);
    }

    getUrlMatchingList(): Observable<TrackingMaterialUrlMatchingResponseDto[]> {
        return this.http.get<TrackingMaterialUrlMatchingResponseDto[]>(
            `${this.baseUrl}/url-matchings`,
        );
    }

    getCountryList(): Observable<TrackingMaterialCountryResponseDto[]> {
        return this.http.get<TrackingMaterialCountryResponseDto[]>(`${this.baseUrl}/countries`);
    }

    updateItemsStatus(dto: UpdateItemsStatusDto): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/update-items-status`, dto);
    }

    updateItemsArchive(dto: UpdateItemsArchiveDto): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/update-items-archive`, dto);
    }

    getWebsiteIdsAndMarketIdsByBookmakerId(
        dto: BookmakerIdDto,
    ): Observable<WebsiteIdsAndMarketIdsResponseDto> {
        return this.http.get<WebsiteIdsAndMarketIdsResponseDto>(
            `${this.baseUrl}/website-ids-and-market-ids-by-bookmaker-id`,
            { params: dtoToHttpParams(dto) },
        );
    }
}
