import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AccumulatorResponseDto,
    AccumulatorResultsDto,
    AddEditAccumulatorDto,
    StatisticResponseDto,
} from 'src/app/core/services/moveup-api/accumulator/accumulator.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class AccumulatorService {
    baseUrl = `${environment.api.baseUrl}/admin/accumulator`;

    constructor(private http: HttpClient) {}

    getList(page: number, limit: number): Observable<Pagination<AccumulatorResponseDto>> {
        return this.http.get<Pagination<AccumulatorResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
        );
    }

    getFormInfos(id: string): Observable<AddEditAccumulatorDto> {
        return this.http.get<AddEditAccumulatorDto>(`${this.baseUrl}/form-infos/${id}`);
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`);
    }

    addAccumulator(dto: AddEditAccumulatorDto): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}`, dto);
    }

    editAccumulator(id: string, dto: AddEditAccumulatorDto): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/${id}`, dto);
    }

    setResults(id: string, dto: AccumulatorResultsDto): Observable<AccumulatorResponseDto> {
        return this.http.put<AccumulatorResponseDto>(`${this.baseUrl}/results/${id}`, dto);
    }

    getStatistics(): Observable<StatisticResponseDto[]> {
        return this.http.get<StatisticResponseDto[]>(`${this.baseUrl}/statistics`);
    }
}
