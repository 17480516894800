import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
} from '@angular/forms';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { take } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { Globals } from 'src/app/core/services/globals';
import { FileResponseDto } from 'src/app/core/services/moveup-api/upload/upload.dtos';
import { UploadService } from 'src/app/core/services/moveup-api/upload/upload.service';

export type AutocompleteOption = { label: string; value: unknown; badge?: string };

@Component({
    selector: 'app-form-upload',
    templateUrl: './upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UploadComponent),
            multi: true,
        },
    ],
})
export class UploadComponent implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() formControlName: string;
    @Input() formControl: UntypedFormControl;
    @Input() folder: string;

    value: string;
    publicUrl: string;

    uploadUrl: string;
    authorizationText: string;

    fileList: NzUploadFile[] = [];

    propagateChange: (_: string) => void;
    propagateTouch: () => void;
    nzData = (file: NzUploadFile): { filename: string } => ({ filename: file.name });

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        public globals: Globals,
        private uploadService: UploadService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.uploadUrl = this.uploadService.getUploadUrl(this.folder);
        this.authService
            .getAccessToken()
            .pipe(take(1))
            .subscribe((token) => {
                this.authorizationText = `Bearer ${token}`;
            });
    }

    ngAfterViewInit(): void {
        if (this.controlContainer && !this.formControl) {
            setTimeout(() => {
                this.formControl = this.controlContainer.control.get(
                    this.formControlName,
                ) as UntypedFormControl;
            });
        }
    }

    setValue(value: string): void {
        this.value = value;
        this.propagateChange(this.value);
    }

    registerOnChange(fn: (_: string) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    handleChange(params: NzUploadChangeParam): void {
        let fileList = [...params.fileList];
        fileList = fileList.slice(-1);
        this.fileList = fileList;

        if (params.type === 'removed') {
            this.setValue(null);
        } else if (params.type === 'success') {
            fileList = fileList.map((file) => {
                const response: FileResponseDto = file.response as FileResponseDto;
                if (response) {
                    file.url = response.url;
                    this.setValue(response.id);
                }

                return file;
            });
        }

        this.fileList = fileList;
    }

    writeValue(value: string): void {
        this.value = value;
        if (value) {
            this.uploadService.getFile(value).subscribe(({ id, url, filename }) => {
                this.fileList = [
                    {
                        uid: id,
                        url,
                        name: filename,
                    },
                ];
            });
        }
    }
}
