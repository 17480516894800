import { Component, Input } from '@angular/core';
import copy from 'copy-to-clipboard';

@Component({
    selector: 'app-shared-copy-text-button',
    templateUrl: './copy-text-button.component.html',
    styleUrls: ['./copy-text-button.component.scss'],
})
export class CopyTextButtonComponent {
    @Input() text: string;
    @Input() label: string;

    copiedText: string;

    copyText(): void {
        copy(this.text);
        this.copiedText = this.text;
        setTimeout(() => {
            this.copiedText = null;
        }, 1000);
    }
}
