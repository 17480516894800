import { NgModule } from '@angular/core';
import { CoreModule } from 'src/app/core/core.module';
import { LayoutModule } from 'src/app/layout/layout.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
    imports: [SharedModule, AppRoutingModule, CoreModule, LayoutModule],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
})
export class AppModule {}
