import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Input,
    Optional,
    SkipSelf,
    TemplateRef,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
} from '@angular/forms';
import { Globals } from 'src/app/core/services/globals';
import { getFormErrors } from 'src/app/shared/utils';

export type AutocompleteOption = { label: string; value: unknown; badge?: string };

@Component({
    selector: 'app-form-textarea',
    templateUrl: './textarea.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextareaComponent),
            multi: true,
        },
    ],
})
export class TextareaComponent implements ControlValueAccessor, AfterViewInit {
    @Input() type: 'text' | 'email' | 'password' = 'text';
    @Input() formControlName: string;
    @Input() formControl: UntypedFormControl;
    @Input() placeholder = '';
    @Input() info?: string | TemplateRef<void>;
    @Input() tooltip?: string | TemplateRef<void>;
    @Input() tooltipPlacement = 'bottomLeft';
    @Input() errorTip: string | TemplateRef<{ $implicit: FormControl }> = 'This field is required';
    @Input() hasFeedback = false;
    @Input() validatingTip = 'Validating...';
    @Input() minRows = 5;
    @Input() maxRows = 20;

    value: string;

    propagateChange: (_: string) => void;
    propagateTouch: () => void;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        public globals: Globals,
    ) {}

    ngAfterViewInit(): void {
        if (this.controlContainer && !this.formControl) {
            setTimeout(() => {
                this.formControl = this.controlContainer.control.get(
                    this.formControlName,
                ) as UntypedFormControl;
            });
        }
    }

    setValue(value: string): void {
        this.value = value;
        this.propagateChange(this.value);
    }

    registerOnChange(fn: (_: string) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    getErrors(): string[] {
        return getFormErrors(this.formControl);
    }
}
