import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditPaymentMethodDto,
    PaymentMethodResponseDto,
} from 'src/app/core/services/moveup-api/bookmaker/payment-methods/payment-methods.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class PaymentMethodService {
    baseUrl = `${environment.api.baseUrl}/admin/bookmaker/payment-method`;

    constructor(private http: HttpClient) {}

    addPaymentMethod(dto: AddEditPaymentMethodDto): Observable<PaymentMethodResponseDto> {
        return this.http.post<PaymentMethodResponseDto>(`${this.baseUrl}`, dto);
    }

    editPaymentMethod(
        paymentMethodId: string,
        dto: AddEditPaymentMethodDto,
    ): Observable<PaymentMethodResponseDto> {
        return this.http.put<PaymentMethodResponseDto>(`${this.baseUrl}/${paymentMethodId}`, dto);
    }

    getList(): Observable<PaymentMethodResponseDto[]> {
        return this.http.get<PaymentMethodResponseDto[]>(`${this.baseUrl}`);
    }

    getPaymentMethod(paymentMethodId: string): Observable<PaymentMethodResponseDto> {
        return this.http.get<PaymentMethodResponseDto>(`${this.baseUrl}/${paymentMethodId}`);
    }

    paymentMethodExists(paymentMethodId: string, name: string): Observable<boolean> {
        return this.http.get<boolean>(
            `${this.baseUrl}/payment-method-exists/${paymentMethodId}/${name}`,
        );
    }

    removePaymentMethod(paymentMethodId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${paymentMethodId}`);
    }
}
