import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-layout-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    showBreakpoints = environment.debug?.showBreakpoints;
    isImpersonated: boolean;

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.authService.getImpersonator().subscribe((impersonator) => {
            this.isImpersonated = !!impersonator;
        });
    }
}
