import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    Component,
    Host,
    Input,
    Optional,
    SkipSelf,
    forwardRef,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
} from '@angular/forms';
import { NzCheckBoxOptionInterface } from 'ng-zorro-antd/checkbox';
import { Globals } from 'src/app/core/services/globals';

export interface CheckboxesOption {
    label: string;
    value: string;
}

@Component({
    selector: 'app-form-checkboxes',
    templateUrl: './checkboxes.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxesComponent),
            multi: true,
        },
    ],
})
export class CheckboxesComponent implements ControlValueAccessor, AfterViewInit {
    @Input() formControlName: string;
    @Input() formControl: UntypedFormControl;
    @Input() options: CheckboxesOption[];
    @Input()
    get useGrid(): boolean {
        return this._useGrid;
    }
    set useGrid(value: BooleanInput) {
        this._useGrid = coerceBooleanProperty(value);
    }
    @Input() colSpan = 24;
    @Input() colXs = null;
    @Input() colSm = null;
    @Input() colMd = null;
    @Input() colLg = null;
    @Input() colXl = null;
    @Input() colXXl = null;

    private _useGrid = false;

    propagateChange: (_: string[]) => void;
    propagateTouch: () => void;

    values: NzCheckBoxOptionInterface[] = [];

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        public globals: Globals,
    ) {}

    ngAfterViewInit(): void {
        if (this.controlContainer && !this.formControl) {
            setTimeout(() => {
                this.formControl = this.controlContainer.control.get(
                    this.formControlName,
                ) as UntypedFormControl;
            });
        }
    }

    registerOnChange(fn: (_: string[]) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(values: string[]): void {
        this.values = [];
        for (const option of this.options) {
            this.values.push({
                label: option.label,
                value: option.value,
                checked: values?.includes(option.value),
            });
        }
    }

    isChecked(value: string): boolean {
        return this.values.find((item) => item.value === value)?.checked;
    }

    updateGroup(values: string[]): void {
        this.values = this.values.map((item) => ({
            ...item,
            checked: values?.includes(item.value),
        }));
        this.propagateChange(this.values.filter((item) => item.checked).map((item) => item.value));
    }

    update(values: NzCheckBoxOptionInterface[]): void {
        this.values = values;
        this.propagateChange(this.values.filter((item) => item.checked).map((item) => item.value));
    }
}
