import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditDataTableDto,
    DataTableResponseDto,
    DataTablesFiltersDto,
} from 'src/app/core/services/moveup-api/data-tables/data-tables.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParams } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataTablesService {
    baseUrl = `${environment.api.baseUrl}/admin/data-table`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: DataTablesFiltersDto,
    ): Observable<Pagination<DataTableResponseDto>> {
        return this.http.get<Pagination<DataTableResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
            { params: dtoToHttpParams(dto) },
        );
    }

    add(dto: AddEditDataTableDto): Observable<DataTableResponseDto> {
        return this.http.post<DataTableResponseDto>(`${this.baseUrl}/add`, dto);
    }

    edit(id: string, dto: AddEditDataTableDto): Observable<DataTableResponseDto> {
        return this.http.put<DataTableResponseDto>(`${this.baseUrl}/edit/${id}`, dto);
    }

    remove(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/remove/${id}`);
    }

    getDataTables(id: string): Observable<DataTableResponseDto> {
        return this.http.get<DataTableResponseDto>(`${this.baseUrl}/${id}`);
    }
}
