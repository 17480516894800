import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Input,
    Optional,
    SkipSelf,
    TemplateRef,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
} from '@angular/forms';
import { Globals } from 'src/app/core/services/globals';

@Component({
    selector: 'app-form-checkbox',
    templateUrl: './checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
})
export class CheckboxComponent implements ControlValueAccessor, AfterViewInit {
    @Input() formControlName: string;
    @Input() formControl: UntypedFormControl;
    @Input() info: string;
    @Input() errorTip: string | TemplateRef<{ $implicit: FormControl }> = 'This field is required';

    value: boolean;

    propagateChange: (_: boolean) => void;
    propagateTouch: () => void;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        public globals: Globals,
    ) {}

    ngAfterViewInit(): void {
        if (this.controlContainer && !this.formControl) {
            setTimeout(() => {
                this.formControl = this.controlContainer.control.get(
                    this.formControlName,
                ) as UntypedFormControl;
            });
        }
    }

    registerOnChange(fn: (_: boolean) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: boolean): void {
        this.value = value;
    }
}
