import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, switchMap, take, tap } from 'rxjs';
import { RequestTokenDto, TokenResponseDto } from 'src/app/core/services/moveup-api/auth/auth.dtos';
import { AuthService as AuthApiService } from 'src/app/core/services/moveup-api/auth/auth.service';
import { UserResponseDto } from 'src/app/core/services/moveup-api/users/users/users.dtos';
import { AuthActions } from 'src/app/store/auth/auth.actions';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';

@Injectable()
export class AuthService {
    isAuthenticated$ = this.store.select(AuthSelectors.selectIsAuthenticated);

    constructor(
        private authApiService: AuthApiService,
        private store: Store,
        private router: Router,
        private notification: NzNotificationService,
    ) {}

    getUser(): Observable<UserResponseDto> {
        return this.store.select(AuthSelectors.selectUser);
    }

    getImpersonator(): Observable<UserResponseDto> {
        return this.store.select(AuthSelectors.selectImpersonator);
    }

    getImpersonatedUser(): Observable<UserResponseDto> {
        return this.store.select(AuthSelectors.selectImpersonatedUser);
    }

    authenticate(dto: RequestTokenDto): Observable<TokenResponseDto> {
        return this.authApiService.requestToken(dto).pipe(
            tap(({ accessToken, refreshToken, user }) => {
                this.store.dispatch(AuthActions.login({ accessToken, refreshToken, user }));
            }),
        );
    }

    impersonate(user: UserResponseDto): void {
        this.store.dispatch(AuthActions.impersonate({ user }));
    }

    switchBack(): void {
        this.store.dispatch(AuthActions.switchBack());
        this.notification.create('success', 'Success', 'You are now back to your original account');
    }

    getInfo(): Observable<UserResponseDto> {
        return this.authApiService.getInfo().pipe(
            tap((user) => {
                this.store.dispatch(AuthActions.updateUser({ user }));
            }),
        );
    }

    refreshToken(): Observable<TokenResponseDto> {
        return this.store.select(AuthSelectors.selectRefreshToken).pipe(
            take(1),
            switchMap((refreshToken) => {
                return this.authApiService.refreshToken({ refreshToken });
            }),
            tap(({ accessToken, refreshToken, user }) => {
                this.store.dispatch(AuthActions.login({ accessToken, refreshToken, user }));
            }),
        );
    }

    logout(): void {
        this.store.dispatch(AuthActions.logout());
        void this.router.navigateByUrl('/auth/login');
    }

    getAccessToken(): Observable<string | undefined> {
        return this.store.select(AuthSelectors.selectAccessToken);
    }
}
