import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CountryQueryDto,
    EditCountryDto,
} from 'src/app/core/services/moveup-api/localization/dtos/country/country-request.dtos';
import { CountryResponseDto } from 'src/app/core/services/moveup-api/localization/dtos/country/country-response.dtos';
import { dtoToHttpParamsV2 } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class CountryService {
    baseUrl = `${environment.api.baseUrl}/admin/localization/countries`;

    constructor(private http: HttpClient) {}

    list(dto?: CountryQueryDto): Observable<CountryResponseDto[]> {
        return this.http.get<CountryResponseDto[]>(`${this.baseUrl}`, {
            ...(dto && { params: dtoToHttpParamsV2(dto) }),
        });
    }

    edit(id: string, dto: EditCountryDto): Observable<CountryResponseDto> {
        return this.http.put<CountryResponseDto>(`${this.baseUrl}/${id}`, dto);
    }
}
