import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Globals } from 'src/app/core/services/globals';
import { BookmakerInformationsResponseDto } from 'src/app/core/services/moveup-api/bookmaker/bookmaker/bookmaker.dtos';
import { WebsiteResponseDto } from 'src/app/core/services/moveup-api/website/website.dtos';

@Component({
    selector: 'app-bookmaker-informations',
    templateUrl: './bookmaker-informations.component.html',
    styleUrls: ['./bookmaker-informations.component.scss'],
})
export class BookmakerInformationsComponent implements OnInit {
    @Input() informations: BookmakerInformationsResponseDto;
    @Input() websites?: WebsiteResponseDto[];

    constructor(
        public globals: Globals,
        @Optional()
        @Inject(NZ_MODAL_DATA)
        public nzModalData: Partial<BookmakerInformationsComponent>,
    ) {}

    ngOnInit(): void {
        if (this.nzModalData) {
            this.informations = this.nzModalData.informations;
            this.websites = this.nzModalData.websites;
        }
    }
}
