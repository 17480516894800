import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditPartnerDto,
    PartnerFiltersDto,
    PartnerNameExistsDto,
    PartnerResponseDto,
} from 'src/app/core/services/moveup-api/affiliate-account/partner/partner.dtos';
import {
    BookmakerNameExistsDto,
    BookmakerResponseDto,
    QuickAddBookmakerDto,
} from 'src/app/core/services/moveup-api/bookmaker/bookmaker/bookmaker.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParams } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class PartnerService {
    baseUrl = `${environment.api.baseUrl}/admin/affiliate-account/partner`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: PartnerFiltersDto,
    ): Observable<Pagination<PartnerResponseDto>> {
        return this.http.get<Pagination<PartnerResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
            { params: dtoToHttpParams(dto) },
        );
    }

    export(dto: PartnerFiltersDto): Observable<PartnerResponseDto[]> {
        return this.http.get<PartnerResponseDto[]>(`${this.baseUrl}/export`, {
            params: dtoToHttpParams(dto),
        });
    }

    add(dto: AddEditPartnerDto): Observable<PartnerResponseDto> {
        return this.http.post<PartnerResponseDto>(`${this.baseUrl}/add`, dto);
    }

    edit(id: string, dto: AddEditPartnerDto): Observable<PartnerResponseDto> {
        return this.http.put<PartnerResponseDto>(`${this.baseUrl}/edit/${id}`, dto);
    }

    nameExists(dto: PartnerNameExistsDto): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/name-exists`, dto);
    }

    bookmakerNameExists(dto: BookmakerNameExistsDto): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/bookmaker-name-exists`, dto);
    }

    bookmakerQuickAdd(dto: QuickAddBookmakerDto): Observable<BookmakerResponseDto> {
        return this.http.post<BookmakerResponseDto>(`${this.baseUrl}/bookmaker-quick-add`, dto);
    }
}
