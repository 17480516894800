import { Component, Input } from '@angular/core';
import copy from 'copy-to-clipboard';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-shortcode',
    templateUrl: './shortcode.component.html',
})
export class ShortcodeComponent {
    @Input() title = 'Shortcode';
    @Input() shortcode: string;

    constructor(private message: NzMessageService) {}

    copy(shortcode: string): void {
        copy(shortcode);
        this.message.info('Shortcode copied!');
    }

    preview(shortcode: string, withSidebar: boolean): void {
        window.open(
            `${environment.wordpressShortcodeDemoUrl}?shortcode=${shortcode}&sidebar=${
                withSidebar ? '1' : '0'
            }`,
            '_blank',
        );
    }
}
