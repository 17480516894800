import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Input,
    Optional,
    SkipSelf,
    TemplateRef,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
} from '@angular/forms';
import * as moment from 'moment';
import { NzDatePickerSizeType } from 'ng-zorro-antd/date-picker';
import { Globals } from 'src/app/core/services/globals';

@Component({
    selector: 'app-form-date-picker',
    templateUrl: './date-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true,
        },
    ],
})
export class DatePickerComponent implements ControlValueAccessor, AfterViewInit {
    @Input() type: 'text' | 'email' | 'password' = 'text';
    @Input() formControlName: string;
    @Input() formControl: UntypedFormControl;
    @Input() placeholder = '';
    @Input() info: string;
    @Input() errorTip: string | TemplateRef<{ $implicit: FormControl }> = 'This field is required';
    @Input() hasFeedback = false;
    @Input() validatingTip = 'Validating...';
    @Input() size: NzDatePickerSizeType = 'large';
    @Input() disabled = false;

    value: Date = null;

    propagateChange: (_: string) => void;
    propagateTouch: () => void;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        public globals: Globals,
    ) {}

    ngAfterViewInit(): void {
        if (this.controlContainer && !this.formControl) {
            setTimeout(() => {
                this.formControl = this.controlContainer.control.get(
                    this.formControlName,
                ) as UntypedFormControl;
            });
        }
    }

    onOpenChange(open: boolean): void {
        if (!open) {
            this.propagateTouch();
        }
    }

    registerOnChange(fn: (_: string) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: string): void {
        this.value = value ? moment(value, 'YYYY-MM-DD').toDate() : null;
    }

    setValue(value: Date): void {
        this.value = value;
        this.propagateChange(value ? moment(value).format('YYYY-MM-DD') : null);
    }
}
