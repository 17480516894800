import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditSerpKeywordDto,
    SerpAnalyzeDto,
    SerpAnalyzeResponseDto,
    SerpKeywordNameExistsDto,
    SerpKeywordResponseDto,
} from 'src/app/core/services/moveup-api/serp/serp.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class SerpService {
    baseUrl = `${environment.api.baseUrl}/admin/serp`;

    constructor(private http: HttpClient) {}

    analyze(dto: SerpAnalyzeDto): Observable<SerpAnalyzeResponseDto> {
        return this.http.post<SerpAnalyzeResponseDto>(`${this.baseUrl}/analyze`, dto);
    }

    manualImport(keywordId: string): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/manual-import/${keywordId}`, {});
    }

    getKeywords(): Observable<SerpKeywordResponseDto[]> {
        return this.http.get<SerpKeywordResponseDto[]>(`${this.baseUrl}/keywords`);
    }

    keywordNameExists(dto: SerpKeywordNameExistsDto): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/keyword-name-exists`, dto);
    }

    addKeyword(dto: AddEditSerpKeywordDto): Observable<SerpKeywordResponseDto> {
        return this.http.post<SerpKeywordResponseDto>(`${this.baseUrl}/keyword`, dto);
    }

    editKeyword(keywordId: string, dto: AddEditSerpKeywordDto): Observable<SerpKeywordResponseDto> {
        return this.http.put<SerpKeywordResponseDto>(`${this.baseUrl}/keyword/${keywordId}`, dto);
    }

    removeKeyword(keywordId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/keyword/${keywordId}`);
    }
}
