export const ALLOWED_USER_ID = {
    VP: 'ebe9b2a2-4489-4cb1-8a81-b20b5a7f9d0f',
    HB: 'd3e929ee-667a-4c28-9b38-0842ef06edd0',
    AP: '0d89df29-2a70-4d17-b434-a5b2cee7f663',
    NR: '961babe5-376f-4fdf-85db-6331edba8b39',
    RM: 'c441fd72-a479-421c-b9fb-9a30189144fc',
    VD: '5874ebe5-8ace-40e8-977f-6648c16bd158',
    MB: 'a543f43f-5508-4ca8-918e-bababd0abc32',
    DB: '4c0edc64-dda3-4f02-868a-b9a928cc9913',
    AM: '3e6b9abc-38ba-4ef0-aaff-836aa26c98e0',
    AO: 'b46ad2b9-521d-4d61-9ec7-7ad57f29a8ae',
    EG: 'fc16ce64-593f-4e28-b332-134d5739b493',
};

export const BASE_ALLOWED_ADMIN_USER_IDS = [
    ALLOWED_USER_ID.VP,
    ALLOWED_USER_ID.HB,
    ALLOWED_USER_ID.AP,
    ALLOWED_USER_ID.NR,
    ALLOWED_USER_ID.RM,
    ALLOWED_USER_ID.VD,
];

export const BASE_ALLOWED_SALE_USER_IDS = [
    ALLOWED_USER_ID.MB,
    ALLOWED_USER_ID.DB,
    ALLOWED_USER_ID.AO,
    ALLOWED_USER_ID.EG,
];
