import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SportType } from 'src/app/core/services/moveup-api';
import { LastUsedLeagueResponseDto } from 'src/app/core/services/moveup-api/common/league/league.dtos';
import { revealWithScaleTrigger } from 'src/app/shared/animations';

@Component({
    selector: 'app-last-used-leagues',
    templateUrl: './last-used-leagues.component.html',
    styleUrls: ['./last-used-leagues.component.scss'],
    animations: [revealWithScaleTrigger],
})
export class LastUsedLeaguesComponent {
    @Input() leagues: LastUsedLeagueResponseDto[];
    @Input() sport: SportType;

    @Output() onSelect = new EventEmitter<LastUsedLeagueResponseDto>();

    get leaguesBySport(): LastUsedLeagueResponseDto[] {
        return this.leagues?.filter((league) => league.sport === this.sport);
    }
}
