import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject, Subscription, combineLatest, interval, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserResponseDto } from 'src/app/core/services/moveup-api/users/users/users.dtos';

@Component({
    selector: 'app-layout-impersonated-header',
    templateUrl: './impersonated-header.component.html',
    styleUrls: ['./impersonated-header.component.scss'],
})
export class ImpersonatedHeaderComponent implements OnInit, OnDestroy {
    impersonationWarningDelay = 1000 * 60 * 5; // 5 minutes

    impersonator: UserResponseDto;
    user: UserResponseDto;

    subscription: Subscription;

    isModalVisible = false;

    onDestroy$ = new Subject<void>();

    constructor(
        private authService: AuthService,
        private modal: NzModalService,
    ) {}

    ngOnInit(): void {
        this._startImpersonationWarning();

        combineLatest([this.authService.getImpersonator(), this.authService.getUser()])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(([impersonator, user]) => {
                this.impersonator = impersonator;
                this.user = user;
            });
    }

    private _startImpersonationWarning(): void {
        this.subscription = interval(this.impersonationWarningDelay).subscribe(() => {
            this.showConfirmImpersonificationModal();
        });
    }

    private _stopImpersonationWarning(): void {
        this.subscription.unsubscribe();
    }

    switchBack(): void {
        this._stopImpersonationWarning();
        this.authService.switchBack();
    }

    showConfirmImpersonificationModal(): void {
        this._stopImpersonationWarning();

        const modal = this.modal.confirm(
            {
                nzTitle: `Hey ${this.impersonator.firstName}!`,
                nzContent: `You are still impersonating ${this.user.firstName} ${this.user.lastName}. Do you want to continue?`,
                nzOkText: 'Continue impersonating',
                nzIconType: 'info-circle',
                nzOkDanger: false,
                nzClosable: false,
                nzMaskClosable: false,
                nzKeyboard: false,

                nzOnOk: () => {
                    modal.close();
                    this._startImpersonationWarning();
                },
                nzOnCancel: () => {
                    modal.close();
                    this.switchBack();
                },
                nzCancelText: 'No, switch back',
            },
            'error',
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
