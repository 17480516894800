<nz-form-item>
    <ng-content></ng-content>
    <nz-form-control
        nzSpan="24"
        *ngIf="formControl"
        [nzAutoTips]="globals.FORM_AUTO_TIPS"
        [nzErrorTip]="errorTip"
        [nzValidateStatus]="formControl"
        [nzExtra]="info"
        [nzHasFeedback]="hasFeedback"
        [nzValidatingTip]="validatingTip"
    >
        <nz-date-picker
            style="width: 100%"
            [nzInputReadOnly]="true"
            [nzSize]="size"
            (nzOnOpenChange)="onOpenChange($event)"
            [nzPlaceHolder]="placeholder"
            [(ngModel)]="value"
            (ngModelChange)="setValue($event)"
            [nzDisabled]="disabled"
        ></nz-date-picker>
    </nz-form-control>
</nz-form-item>
