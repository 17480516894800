<nz-form-item *ngIf="formControl">
    <ng-content></ng-content>
    <nz-form-control [nzAutoTips]="globals.FORM_AUTO_TIPS" [nzValidateStatus]="formControl">
        <nz-upload
            [nzAction]="uploadUrl"
            [nzHeaders]="{ authorization: authorizationText }"
            nzListType="picture"
            nzAccept="image/*"
            [nzLimit]="1"
            [nzFileList]="fileList"
            [nzData]="nzData"
            (nzChange)="handleChange($event)"
        >
            <button nz-button type="button">
                <span nz-icon nzType="upload"></span>
                Click to Upload
            </button>
        </nz-upload>
    </nz-form-control>
</nz-form-item>
