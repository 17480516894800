import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LAYOUT_COMPONENTS } from 'src/app/layout/components';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    imports: [SharedModule, RouterModule],
    declarations: [...LAYOUT_COMPONENTS],
})
export class LayoutModule {}
