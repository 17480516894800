/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
    BASE_ALLOWED_ADMIN_USER_IDS,
    BASE_ALLOWED_SALE_USER_IDS,
} from 'src/app/core/guards/custom-guards/fixtures';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

export const SCRAPING_GUARD_ALLOWED_USER_IDS = [
    ...BASE_ALLOWED_ADMIN_USER_IDS,
    ...BASE_ALLOWED_SALE_USER_IDS,
];

@Injectable()
export class ScrapingGuard {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) {}

    canActivate(_route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authService.getUser().pipe(
            switchMap((user) => {
                const allowed =
                    SCRAPING_GUARD_ALLOWED_USER_IDS.includes(user.id) ||
                    environment.bypassCustomGuards;
                if (!allowed) {
                    void this.router.navigateByUrl('/');

                    return of(false);
                }

                return of(true);
            }),
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.canActivate(route);
    }
}
