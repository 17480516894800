import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    BonusesFiltersDto,
    BonusesResponseDto,
} from 'src/app/core/services/moveup-api/bookmaker/bonuses/bonuses.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParams } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class BonusesService {
    baseUrl = `${environment.api.baseUrl}/admin/bookmaker/bonuses`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: BonusesFiltersDto,
    ): Observable<Pagination<BonusesResponseDto>> {
        return this.http.get<Pagination<BonusesResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
            { params: dtoToHttpParams(dto) },
        );
    }
}
