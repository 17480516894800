<nz-form-item>
    <ng-content select="[slot=title]"></ng-content>
    <nz-form-control
        *ngIf="formControl"
        [nzAutoTips]="globals.FORM_AUTO_TIPS"
        [nzValidateStatus]="formControl"
        [nzExtra]="info"
        [nzErrorTip]="errorTip"
    >
        <label nz-checkbox [formControl]="formControl">
            <ng-content></ng-content>
        </label>
    </nz-form-control>
</nz-form-item>
