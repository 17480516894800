// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from 'src/environments/environment.interface';

export const environment: IEnvironment = {
    production: true,
    splashScreen: true,
    api: {
        basicToken: '912a49c70dd4087876d4f3608908dee31261c1270b198ce769589d4e8ca3be6e',
        baseUrl: 'https://buix-api.moveup.media',
    },
    httpDelay: 0,
    wordpressDemoUrl: 'https://mi-apuesta.com/apostas-brasil',
    wordpressShortcodeDemoUrl: 'https://mi-apuesta.com/mantosnew/demo-shortcode',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error'; // Included with Angular CLI.
