import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RegionResponseDto } from 'src/app/core/services/moveup-api/localization/dtos/region/region-response.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class RegionService {
    baseUrl = `${environment.api.baseUrl}/admin/localization/regions`;

    constructor(private http: HttpClient) {}

    listByCountry(countryId: string): Observable<RegionResponseDto[]> {
        return this.http.get<RegionResponseDto[]>(`${this.baseUrl}/country/${countryId}`);
    }
}
