import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileResponseDto } from 'src/app/core/services/moveup-api/upload/upload.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class UploadService {
    baseUrl = `${environment.api.baseUrl}/admin/common/upload`;

    constructor(private http: HttpClient) {}

    getUploadUrl(folder: string): string {
        return `${this.baseUrl}/${folder}`;
    }

    getFile(id: string): Observable<FileResponseDto> {
        return this.http.get<FileResponseDto>(`${this.baseUrl}/${id}`);
    }
}
