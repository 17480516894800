<nz-form-item>
    <ng-content></ng-content>
    <nz-checkbox-group
        *ngIf="!useGrid"
        [(ngModel)]="values"
        (ngModelChange)="update($event)"
    ></nz-checkbox-group>
    <nz-checkbox-wrapper *ngIf="useGrid" (nzOnChange)="updateGroup($event)">
        <div nz-row>
            <div
                *ngFor="let option of options"
                nz-col
                [nzSpan]="colSpan"
                [nzXs]="colXs"
                [nzSm]="colSm"
                [nzMd]="colMd"
                [nzLg]="colLg"
                [nzXl]="colXl"
                [nzXXl]="colXXl"
            >
                <label nz-checkbox [nzValue]="option.value" [ngModel]="isChecked(option.value)">
                    {{ option.label }}
                </label>
            </div>
        </div>
    </nz-checkbox-wrapper>
</nz-form-item>
