/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import en from '@angular/common/locales/en';
import {
    APP_INITIALIZER,
    ErrorHandler,
    LOCALE_ID,
    NgModule,
    Optional,
    SkipSelf,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import * as Sentry from '@sentry/angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NZ_DATE_CONFIG, NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NgxMaskModule } from 'ngx-mask';
import { CORE_GUARDS } from 'src/app/core/guards';
import { HttpRequestInterceptor } from 'src/app/core/interceptors/http-request.interceptor';
import { HttpResponseInterceptor } from 'src/app/core/interceptors/http-response.interceptor';
import { CORE_SERVICES } from 'src/app/core/services';
import { Globals } from 'src/app/core/services/globals';
import { HttpHelper } from 'src/app/core/services/http.helper';
import { metaReducers, rootReducer } from 'src/app/store';
import { environment } from 'src/environments/environment';
registerLocaleData(en);

@NgModule({
    imports: [
        BrowserAnimationsModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        StoreModule.forRoot(rootReducer, { metaReducers }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        NgxMaskModule.forRoot(),
        AngularSvgIconModule.forRoot(),
    ],
    providers: [
        ...CORE_SERVICES,
        ...CORE_GUARDS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpResponseInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
        HttpHelper,

        Globals,
        { provide: LOCALE_ID, useValue: 'en-US' },
        { provide: NZ_I18N, useValue: en_US },
        {
            provide: NZ_DATE_CONFIG,
            useValue: {
                firstDayOfWeek: 1,
            },
        },

        /**
         * Sentry.io
         */
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    exports: [LoadingBarHttpClientModule, LoadingBarRouterModule, BrowserAnimationsModule],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
