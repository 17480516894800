<div class="ImpersonatedHeader">
    <div>
        👋 Hey {{ impersonator.firstName }}, be careful, you are impersonating
        <strong>{{ user.firstName }} {{ user.lastName }}</strong>!
    </div>
    <button nz-button nzType="primary" (click)="switchBack()">
        <span nz-icon nzType="swap" nzTheme="outline"></span>
        Switch back
    </button>
</div>
