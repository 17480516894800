/* eslint-disable @typescript-eslint/no-explicit-any */

import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

interface HttpErrorDto {
    statusCode: number;
    error: string;
}

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiRequest = req.url.startsWith(environment.api.baseUrl);

        if (isApiRequest) {
            return next.handle(req).pipe(delay(environment.httpDelay));
        }

        return next.handle(req).pipe(
            catchError((error: unknown) => {
                if (isApiRequest && error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 404:
                            void this._redirect(['/error', 'not-found']);
                            break;
                        case 401:
                            this.authService.logout();
                            break;
                        case 403:
                            void this._redirect(['/error', 'forbidden']);
                            break;
                        case 500:
                            void this._redirect(['/error']);
                            break;
                        case 400:
                            Object.assign(error, {
                                message: (error.error as HttpErrorDto).error,
                            });
                            break;
                        default:
                            break;
                    }
                }

                return throwError(() => error);
            }),
        );
    }

    private _redirect(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        // this.dialogService.close();

        return this.router.navigate(commands, extras);
    }
}
