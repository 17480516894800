import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class HttpHelper {
    constructor(private router: Router) {}

    catchError(error: unknown, errorMessages: [string, string][]): string | void {
        if (error instanceof HttpErrorResponse) {
            const { status, message } = error;
            if (status === 400) {
                const mapping = errorMessages.find((item) => item[0] === message);
                if (mapping) {
                    return mapping[1];
                }
            }
        }

        void this.router.navigate(['/error']);
    }
}
