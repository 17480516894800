import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonBookmakerResponseDto } from 'src/app/core/services/moveup-api/common/bookmaker/bookmaker.dtos';
import { environment } from 'src/environments/environment';
import { CommonBookmakerWebsiteResponseDto } from './bookmaker-website.dtos';

@Injectable()
export class CommonBookmakerWebsiteService {
    baseUrl = `${environment.api.baseUrl}/admin/common/bookmaker-website`;

    constructor(private http: HttpClient) {}

    getList(websiteId: string): Observable<CommonBookmakerWebsiteResponseDto[]> {
        return this.http.get<CommonBookmakerResponseDto[]>(`${this.baseUrl}/list/${websiteId}`);
    }
}
