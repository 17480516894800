import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserType } from 'src/app/core/services/moveup-api/users/users/users.dtos';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[isUserType]' })
export class IsUserTypeDirective implements OnDestroy {
    @Input() set isUserType(type: UserType) {
        this.authService
            .getUser()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((user) => {
                if (!this.hasView && user?.type === type) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                    this.hasView = true;
                } else if (this.hasView && user?.type !== type) {
                    this.viewContainer.clear();
                    this.hasView = false;
                }
            });
    }

    hasView = false;
    onDestroy$ = new Subject<void>();

    constructor(
        private authService: AuthService,
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
