import { Component } from '@angular/core';
import { activeGrids$, breakpoint$, windowWidth$ } from 'src/app/shared/utils';

@Component({
    selector: 'app-layout-breakpoint',
    templateUrl: './breakpoint.component.html',
    styleUrls: ['./breakpoint.component.scss'],
})
export class BreakpointComponent {
    breakpoint$ = breakpoint$;
    grids$ = activeGrids$;
    width$ = windowWidth$;
}
