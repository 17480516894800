import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditCompetitorDto,
    AddEditCompetitorWebsiteDto,
    CompetitorBadgeTextExistsDto,
    CompetitorExistsDto,
    CompetitorResponseDto,
    CompetitorWebsiteExistsDto,
    CompetitorWebsiteResponseDto,
    CompetitorWebsiteUrlExistsDto,
} from 'src/app/core/services/moveup-api/competitor/competitor.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class CompetitorService {
    baseUrl = `${environment.api.baseUrl}/admin/competitor`;

    constructor(private http: HttpClient) {}

    getList(): Observable<CompetitorResponseDto[]> {
        return this.http.get<CompetitorResponseDto[]>(`${this.baseUrl}/list`);
    }

    addCompetitor(dto: AddEditCompetitorDto): Observable<CompetitorResponseDto> {
        return this.http.post<CompetitorResponseDto>(`${this.baseUrl}/add`, dto);
    }

    editCompetitor(
        competitorId: string,
        dto: AddEditCompetitorDto,
    ): Observable<CompetitorResponseDto> {
        return this.http.put<CompetitorResponseDto>(`${this.baseUrl}/edit/${competitorId}`, dto);
    }

    removeCompetitor(competitorId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/remove/${competitorId}`);
    }

    addCompetitorWebsite(
        competitorId: string,
        dto: AddEditCompetitorWebsiteDto,
    ): Observable<CompetitorWebsiteResponseDto> {
        return this.http.post<CompetitorWebsiteResponseDto>(
            `${this.baseUrl}/website/add/${competitorId}`,
            dto,
        );
    }

    editCompetitorWebsite(
        competitorWebsiteId: string,
        dto: AddEditCompetitorWebsiteDto,
    ): Observable<CompetitorWebsiteResponseDto> {
        return this.http.put<CompetitorWebsiteResponseDto>(
            `${this.baseUrl}/website/edit/${competitorWebsiteId}`,
            dto,
        );
    }

    renmoveCompetitorWebsite(competitorWebsiteId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/website/remove/${competitorWebsiteId}`);
    }

    competitorExists(competitorId: string, dto: CompetitorExistsDto): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/exists/${competitorId}`, dto);
    }

    competitorBadgeTextExists(
        competitorId: string,
        dto: CompetitorBadgeTextExistsDto,
    ): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/badge-text-exists/${competitorId}`, dto);
    }

    competitorWebsiteExists(
        competitorId: string,
        competitorWebsiteId: string,
        dto: CompetitorWebsiteExistsDto,
    ): Observable<boolean> {
        return this.http.post<boolean>(
            `${this.baseUrl}/website/exists/${competitorId}/${competitorWebsiteId}`,
            dto,
        );
    }

    competitorWebsiteUrlExists(
        competitorId: string,
        competitorWebsiteId: string,
        dto: CompetitorWebsiteUrlExistsDto,
    ): Observable<boolean> {
        return this.http.post<boolean>(
            `${this.baseUrl}/website/url-exists/${competitorId}/${competitorWebsiteId}`,
            dto,
        );
    }
}
