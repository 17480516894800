import {
    CommonBookmakerAccountsResponseDto,
    CommonBookmakerResponseDto,
} from 'src/app/core/services/moveup-api/common/bookmaker/bookmaker.dtos';
import {
    TrackingMaterialCategoryType,
    TrackingMaterialType,
} from 'src/app/core/services/moveup-api/tracking-material/tracking-material.dtos';

/**
 * Enums / Types
 */
export enum ScrapingRunStatus {
    DONE = 'done',
    ERROR = 'error',
    IN_PROGRESS = 'in_progress',
    SCHEDULED = 'scheduled',
    AWAITING_EXECUTION = 'awaiting_execution',
}

export enum ScrapingCallStatus {
    DONE = 'done',
    ERROR = 'error',
}

export type ScrapingRunListOrderByType = 'scheduledAt' | 'scrapedAt' | 'status';
export type ScrapingRunListOrderDirectionType = 'asc' | 'desc';

export type ScrapingDashboardFilterFirstDimensionType =
    | 'BOOKMAKER'
    | 'WEBSITE'
    | 'MARKET'
    | 'TRACKING_TYPE';
export type ScrapingDashboardFilterDateRangeType =
    | 'LAST_30_DAYS'
    | 'LAST_13_MONTHS'
    | 'CURRENT_MONTH'
    | 'LAST_MONTH'
    | 'CURRENT_YEAR'
    | 'LAST_YEAR'
    | 'CUSTOM';
export type ScrapingDashboardFilterDateGroupType = 'DAY' | 'MONTH';
export type ScrapingDashboardDataMetricType =
    | 'CLICK'
    | 'NEW_DEPOSITING_ACCOUNTS'
    | 'REGISTRATIONS'
    | 'CPA_COMMISSIONS'
    | 'REVENUE_SHARING_COMMISSIONS'
    | 'REFERRAL_COMMISSIONS'
    | 'TOTAL_INCOME';

/**
 * Requests
 */

export class ScrapingDashboardFiltersDto {
    bookmakerIds?: string[];
    bookmakerAccountIds?: string[];
    websiteIds?: string[];
    trackingMaterialTypes?: TrackingMaterialType[];
    trackingMaterialActivityIds?: string[];
    trackingMaterialCategories?: TrackingMaterialCategoryType[];
    marketIds?: string[];
    trackingMaterialPid?: string;
    firstDimension: ScrapingDashboardFilterFirstDimensionType;
    dateFrom: string; // YYYY-MM-DD
    dateTo: string; // YYYY-MM-DD
    dateGroup: ScrapingDashboardFilterDateGroupType;
}

/**
 * @deprecated
 */
export class ScrapingDashboardDataOldFiltersDto {
    bookmakerId: string;
    bookmakerAccountId: string;
    dateFrom: Date;
    dateTo: Date;
}

export class ScrapingDashboardMonitoringFiltersDto {
    dateFrom: string; // YYYY-MM-DD
    dateTo: string; // YYYY-MM-DD
}

export class ScrapingRunListDto {
    statuses: ScrapingRunStatus[];
    orderBy: ScrapingRunListOrderByType;
    orderDirection: ScrapingRunListOrderDirectionType;
}

/**
 * Responses
 */

export class ScrapingDashboardDataResponseDto {
    header1: { name: string; cols: number }[];
    header2: string[];
    totals: Record<ScrapingDashboardDataMetricType, number>[];
    rows: ScrapingDashboardDataRowResponseDto[];
}

/**
 * @deprecated
 */
export class ScrapingDashboardDataOldResponseDto {
    id: string;
    trackingPid: string;
    bookmaker: CommonBookmakerResponseDto;
    bookmakerAccount: CommonBookmakerAccountsResponseDto;
    date: Date;
    clicks: number;
    newDepositingAccounts: number;
    registrations: number;
    cpaCommissions: number;
    revenueSharingCommissions: number;
    referralCommissions: number;
    totalIncome: number;
    currency: string;
}

export class ScrapingDashboardRunsMonitoringResponseDto {
    header1: { name: string; cols: number }[];
    header2: string[];
    bookmakers: ScrapingDashboardRunsMonitoringBookmakerResponseDto[];
}

export class ScrapingRunResponseDto {
    id: number;
    instance: ScrapingInstanceResponseDto;
    status: ScrapingRunStatus;
    referenceDate: string;
    scheduledAt: Date;
    scrapedAt: Date;
    duration: number;
    numberOfDays: number;
    numberOfCalls: number;
}

export class MappedDataResponseDto {
    trackingPid: string;
    date: Date;
    clicks: number;
    newDepositingAccounts: number;
    registrations: number;
    cpaCommissions: number;
    revenueSharingCommissions: number;
    referralCommissions: number;
    totalIncome: number;
    currency: string;
}

export class ScrapedDataResponseDto {
    rawData: string[];
    mappedDatas: MappedDataResponseDto[];
}

export class ScrapingRunDetailResponseDto extends ScrapingRunResponseDto {
    calls: ScrapingCallResponseDto[];
    scrapedDatas: ScrapedDataResponseDto[];
}

export class ScrapingInstanceBookmakerAccountIds {
    ids: string[];
}

export class ScrapingInstanceResponseDto {
    id: number;
    name: string;
    scrapingMethodName: string;
    numberOfDaysByRun: number;
    dailySchedule: string[];
    bookmakers: CommonBookmakerResponseDto[];
    bookmakerAccount: CommonBookmakerAccountsResponseDto;
}

export class ScraperInstanceDetailsResponseDto extends ScrapingInstanceResponseDto {
    endpoint: string;
    apiEndpoint?: string;
    apiKey?: string;
    username?: string;
    password: string;
    disabled: boolean;
    template: ScraperTemplateResponseDto;
}

/**
 * Nested responses
 */

export class ScrapingCallResponseDto {
    date: Date;
    status: ScrapingCallStatus;
    duration: number;
    errorCode: number;
    errorData: string;
}

export class ScrapingDashboardDataRowResponseDto {
    name: string;
    metrics: Record<ScrapingDashboardDataMetricType, number>[];
}

export class ScrapingDashboardRunsMonitoringBookmakerResponseDto {
    name: string;
    accounts: ScrapingDashboardRunsMonitoringAccountResponseDto[];
}

export class ScrapingDashboardRunsMonitoringRunIndosResponseDto {
    runId: number;
    status: ScrapingRunStatus;
    numberOfMappedDatas: number;
}

export class ScrapingDashboardRunsMonitoringAccountResponseDto {
    name: string;
    infos: ScrapingDashboardRunsMonitoringRunIndosResponseDto[];
}

export class ScraperTemplateResponseDto {
    name: string;
    mapping: Record<string, string>;
    method: ScrapingMethodResponseDto;
}

export class ScrapingMethodResponseDto {
    name: string;
    authType: 'api_key' | 'username_password';
}
