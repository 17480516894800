<nz-form-item>
    <ng-content></ng-content>
    <nz-form-control
        *ngIf="formControl"
        [nzAutoTips]="globals.FORM_AUTO_TIPS"
        [nzErrorTip]="errorTip"
        [nzValidateStatus]="formControl"
        [nzExtra]="info"
        [nzHasFeedback]="hasFeedback"
    >
        <nz-select
            [formControl]="formControl"
            nzMode="tags"
            [nzAllowClear]="true"
            [nzPlaceHolder]="placeholder"
            [nzTokenSeparators]="[',']"
            nzDropdownClassName="TagsDropdown"
            nzSize="large"
            nz-tooltip
            [nzTooltipTitle]="tooltip"
            [nzTooltipPlacement]="tooltipPlacement"
        >
        </nz-select>
    </nz-form-control>
</nz-form-item>
