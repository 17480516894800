import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SportType } from 'src/app/core/services/moveup-api';
import { FixtureResponseDto } from 'src/app/core/services/moveup-api/common/fixture/fixture.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class FixtureService {
    baseUrl = `${environment.api.baseUrl}/admin/common/fixture`;

    constructor(private http: HttpClient) {}

    getList(sport: SportType, leagueId: number, date: string): Observable<FixtureResponseDto[]> {
        return this.http.get<FixtureResponseDto[]>(
            `${this.baseUrl}/list/${sport}/${leagueId}/${date}`,
        );
    }
}
