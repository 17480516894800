import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserResponseDto } from 'src/app/core/services/moveup-api/users/users/users.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    private _isRefreshing = false;
    private _refreshTokenSubject = new BehaviorSubject<string>(null);

    constructor(private store: Store, private authService: AuthService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return combineLatest([
            this.authService.getAccessToken(),
            this.authService.getImpersonatedUser(),
        ]).pipe(
            take(1),
            switchMap(([token, impersonatedUser]) => {
                if (req.url.startsWith(environment.api.baseUrl)) {
                    req = this._addAuthorization(req, token, impersonatedUser);
                }

                return next.handle(req);
            }),
            catchError((error: unknown) => {
                if (
                    req.url.startsWith(environment.api.baseUrl) &&
                    error instanceof HttpErrorResponse &&
                    error.status === 401 &&
                    !req.url.includes('/refresh-token')
                ) {
                    return this._handle401Error(req, next);
                }

                return throwError(() => error);
            }),
        );
    }

    private _handle401Error(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        if (!this._isRefreshing) {
            this._isRefreshing = true;
            this._refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap(({ accessToken }) => {
                    this._isRefreshing = false;
                    this._refreshTokenSubject.next(accessToken);

                    return next.handle(this._addAuthorization(request, accessToken));
                }),
                catchError((err: unknown) => {
                    this._isRefreshing = false;
                    this.authService.logout();

                    return throwError(() => err);
                }),
            );
        }

        return this._refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token) => next.handle(this._addAuthorization(request, token))),
        );
    }

    private _addAuthorization(
        request: HttpRequest<unknown>,
        token: string,
        impersonatedUser?: UserResponseDto,
    ): HttpRequest<unknown> {
        const authorization = ['Basic ' + environment.api.basicToken];
        if (token) {
            authorization.push('Bearer ' + token);
        }

        return request.clone({
            setHeaders: {
                Authorization: authorization,
                ...(impersonatedUser ? { 'X-Impersonate-User': impersonatedUser.id } : {}),
            },
        });
    }
}
