<div class="CopyTextButton">
    {{ label || text }}
    <button
        nz-button
        nzType="link"
        nz-tooltip
        [nzTooltipTitle]="copiedText === text ? 'Copied' : 'Copy'"
        (click)="copyText()"
    >
        <span
            nz-icon
            [nzType]="copiedText === text ? 'check' : 'copy'"
            [ngClass]="{ 'text-green': copiedText === text }"
        ></span>
    </button>
</div>
