import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditHoldingDto,
    HoldingResponseDto,
} from 'src/app/core/services/moveup-api/bookmaker/holding/holding.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class HoldingService {
    baseUrl = `${environment.api.baseUrl}/admin/bookmaker/holding`;

    constructor(private http: HttpClient) {}

    getList(page: number, limit: number): Observable<Pagination<HoldingResponseDto>> {
        return this.http.get<Pagination<HoldingResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
        );
    }

    getFormInfos(id: string): Observable<AddEditHoldingDto> {
        return this.http.get<AddEditHoldingDto>(`${this.baseUrl}/form-infos/${id}`);
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`);
    }

    addHolding(dto: AddEditHoldingDto): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}`, dto);
    }

    editHolding(id: string, dto: AddEditHoldingDto): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/${id}`, dto);
    }
}
