import { createReducer, on } from '@ngrx/store';
import { AuthActions } from 'src/app/store/auth/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';

export const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
    accessToken: null,
    refreshToken: null,
    impersonator: null,
};

export const authReducer = createReducer(
    initialState,
    on(AuthActions.login, (state, { user, accessToken, refreshToken }) => ({
        ...state,
        isAuthenticated: true,
        user,
        accessToken,
        refreshToken,
    })),
    on(AuthActions.updateUser, (state, { user }) => ({
        ...state,
        user,
    })),
    on(AuthActions.logout, (state) => {
        return { ...initialState };
    }),
    on(AuthActions.impersonate, (state, { user }) => {
        return { ...state, user, impersonator: state.impersonator || state.user };
    }),
    on(AuthActions.switchBack, (state) => {
        if (!state.impersonator) {
            return { ...state };
        }

        return { ...state, user: state.impersonator, impersonator: null };
    }),
);
