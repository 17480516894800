import { Injectable } from '@angular/core';
// import { Workbook } from 'exceljs';
import * as ExcelJS from 'exceljs';
import * as fs from 'file-saver';
import moment from 'moment';

@Injectable()
export class ExcelService {
    async generate(
        name: string,
        columns: Partial<ExcelJS.Column>[],
        data: unknown[],
    ): Promise<void> {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(name);

        worksheet.columns = columns.map((column) => {
            column.fill = {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: 'FEE98C00' },
                fgColor: { argb: '222B4500' },
            };

            return column;
        });

        worksheet.addRows(data);
        const xls = await workbook.xlsx.writeBuffer();
        const blob = new Blob([xls], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const filename = name + ' - ' + moment().format('DD-MM-YYYY HH--mm').replace('--', 'H');
        fs.saveAs(blob, `${filename}.xlsx`);
    }
}
