import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditCrudExampleDto,
    CrudExampleFiltersDto,
    CrudExampleResponseDto,
} from 'src/app/core/services/moveup-api/crud-example/crud-example.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParams } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class CrudExampleService {
    baseUrl = `${environment.api.baseUrl}/admin/crud-example`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: CrudExampleFiltersDto,
    ): Observable<Pagination<CrudExampleResponseDto>> {
        return this.http.get<Pagination<CrudExampleResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
            { params: dtoToHttpParams(dto) },
        );
    }

    add(dto: AddEditCrudExampleDto): Observable<CrudExampleResponseDto> {
        return this.http.post<CrudExampleResponseDto>(`${this.baseUrl}/add`, dto);
    }

    edit(id: string, dto: AddEditCrudExampleDto): Observable<CrudExampleResponseDto> {
        return this.http.put<CrudExampleResponseDto>(`${this.baseUrl}/edit/${id}`, dto);
    }

    remove(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/remove/${id}`);
    }

    getCrudExample(id: string): Observable<CrudExampleResponseDto> {
        return this.http.get<CrudExampleResponseDto>(`${this.baseUrl}/${id}`);
    }
}
