import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    RefreshTokenDto,
    RequestTokenDto,
    TokenResponseDto,
} from 'src/app/core/services/moveup-api/auth/auth.dtos';
import { UserResponseDto } from 'src/app/core/services/moveup-api/users/users/users.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
    baseUrl = `${environment.api.baseUrl}/admin/auth`;

    constructor(private http: HttpClient) {}

    requestToken(dto: RequestTokenDto): Observable<TokenResponseDto> {
        return this.http.post<TokenResponseDto>(`${this.baseUrl}/token`, dto);
    }

    refreshToken(dto: RefreshTokenDto): Observable<TokenResponseDto> {
        return this.http.post<TokenResponseDto>(`${this.baseUrl}/refresh-token`, dto);
    }

    getInfo(): Observable<UserResponseDto> {
        return this.http.get<UserResponseDto>(`${this.baseUrl}/info`);
    }
}
