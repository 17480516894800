import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Input,
    Optional,
    SkipSelf,
    TemplateRef,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
} from '@angular/forms';
import { Globals } from 'src/app/core/services/globals';

export interface RadioOption<T = unknown> {
    value: T;
    label: string;
}

@Component({
    selector: 'app-form-radios',
    templateUrl: './radios.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadiosComponent),
            multi: true,
        },
    ],
})
export class RadiosComponent implements ControlValueAccessor, AfterViewInit {
    @Input() formControlName: string;
    @Input() formControl: UntypedFormControl;
    @Input() options: RadioOption[] = [];
    @Input() type: 'radio' | 'button' = 'radio';
    @Input() buttonSize: 'large' | 'small' | 'default' = 'default';
    @Input() buttonStyle: 'solid' | 'outline' = 'outline';
    @Input() info: string;
    @Input() errorTip: string | TemplateRef<{ $implicit: FormControl }> = 'This field is required';

    value: unknown;

    propagateChange: (_: unknown) => void;
    propagateTouch: () => void;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        public globals: Globals,
    ) {}

    ngAfterViewInit(): void {
        if (this.controlContainer && !this.formControl) {
            setTimeout(() => {
                this.formControl = this.controlContainer.control.get(
                    this.formControlName,
                ) as UntypedFormControl;
            });
        }
    }

    setValue(value: unknown): void {
        this.value = value;
        this.propagateChange(this.value);
    }

    registerOnChange(fn: (_: unknown) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: unknown): void {
        this.value = value;
    }
}
