import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
    Sentry.init({
        dsn: 'https://cdae6394d69c0a3326baf69433e3d3e6@o4507505980997632.ingest.de.sentry.io/4507505985060944',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllInputs: false,
                maskAllText: false,
            }),
            Sentry.captureConsoleIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/buix.moveup.media/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    enableProdMode();
}

void platformBrowserDynamic().bootstrapModule(AppModule);
