<div class="LastUsedLeagues" *ngIf="leaguesBySport?.length > 0" [@revealWithScale]>
    <h4>Last used leagues</h4>
    <nz-space nzWrap="wrap">
        <ng-container *ngFor="let league of leaguesBySport">
            <button
                *nzSpaceItem
                nz-button
                class="mb-2"
                nzType="dashed"
                type="button"
                (click)="onSelect.emit(league)"
            >
                {{ league.name }}
            </button>
        </ng-container>
    </nz-space>
</div>
