<nz-form-item *ngIf="formControl">
    <ng-content></ng-content>
    <nz-rate
        [ngModel]="value"
        (ngModelChange)="setValue($event)"
        (nzOnBlur)="propagateTouch()"
        [nzAllowClear]="allowClear"
        nzAllowHalf
    ></nz-rate>
</nz-form-item>
