import { Component, Input } from '@angular/core';
import { ScrapingRunStatus } from 'src/app/core/services/moveup-api/scraping/scraping.dtos';

@Component({
    selector: 'app-scraping-run-status-label',
    templateUrl: './scraping-run-status-label.component.html',
})
export class ScrapingRunStatusLabelComponent {
    @Input() status: ScrapingRunStatus;

    getLabel(): string {
        if (this.status === ScrapingRunStatus.DONE) {
            return 'Done';
        }

        if (this.status === ScrapingRunStatus.ERROR) {
            return 'Error';
        }

        if (this.status === ScrapingRunStatus.IN_PROGRESS) {
            return 'In progress';
        }

        if (this.status === ScrapingRunStatus.SCHEDULED) {
            return 'Scheduled';
        }

        if (this.status === ScrapingRunStatus.AWAITING_EXECUTION) {
            return 'Awaiting execution';
        }

        return 'N/A';
    }

    getColor(): string {
        if (this.status === ScrapingRunStatus.DONE) {
            return 'green';
        }

        if (this.status === ScrapingRunStatus.ERROR) {
            return 'red';
        }

        if (this.status === ScrapingRunStatus.IN_PROGRESS) {
            return 'blue';
        }

        if (this.status === ScrapingRunStatus.SCHEDULED) {
            return 'gold';
        }

        if (this.status === ScrapingRunStatus.AWAITING_EXECUTION) {
            return 'purple';
        }

        return null;
    }
}
