import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ScrapingDashboardDataOldFiltersDto,
    ScrapingDashboardDataOldResponseDto,
    ScrapingDashboardDataResponseDto,
    ScrapingDashboardFiltersDto,
    ScrapingDashboardMonitoringFiltersDto,
    ScrapingDashboardRunsMonitoringResponseDto,
    ScrapingInstanceBookmakerAccountIds,
    ScrapingRunDetailResponseDto,
    ScrapingRunListDto,
    ScrapingRunResponseDto,
} from 'src/app/core/services/moveup-api/scraping/scraping.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParams } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class ScrapingService {
    baseUrl = `${environment.api.baseUrl}/admin/scraping`;

    constructor(private http: HttpClient) {}

    getData(dto: ScrapingDashboardFiltersDto): Observable<ScrapingDashboardDataResponseDto> {
        return this.http.get<ScrapingDashboardDataResponseDto>(`${this.baseUrl}/dashboard/data`, {
            params: dtoToHttpParams(dto),
        });
    }

    /**
     * @deprecated
     */
    getDataOld(
        dto: ScrapingDashboardDataOldFiltersDto,
    ): Observable<ScrapingDashboardDataOldResponseDto[]> {
        return this.http.get<ScrapingDashboardDataOldResponseDto[]>(
            `${this.baseUrl}/dashboard/data-old`,
            {
                params: dtoToHttpParams(dto),
            },
        );
    }

    getRunsMonitoring(
        dto: ScrapingDashboardMonitoringFiltersDto,
    ): Observable<ScrapingDashboardRunsMonitoringResponseDto> {
        // return of({
        //     header1: [
        //         { name: 'Avril', cols: 12 },
        //         { name: 'Mai', cols: 18 },
        //     ],
        //     header2: [
        //         '20',
        //         '21',
        //         '22',
        //         '23',
        //         '24',
        //         '25',
        //         '26',
        //         '27',
        //         '28',
        //         '29',
        //         '30',
        //         '31',
        //         '1',
        //         '2',
        //         '3',
        //         '4',
        //         '5',
        //         '6',
        //         '7',
        //         '8',
        //         '9',
        //         '10',
        //         '11',
        //         '12',
        //         '13',
        //         '14',
        //         '15',
        //         '16',
        //         '17',
        //         '18',
        //     ],
        //     bookmakers: [
        //         {
        //             name: 'Bookmaker 1',
        //             accounts: [
        //                 {
        //                     name: 'Account 1',
        //                     states: [
        //                         'NONE',
        //                         'OK',
        //                         'KO',
        //                         'OK',
        //                         'OK',
        //                         'NONE',
        //                         'OK',
        //                         'KO',
        //                         'OK',
        //                         'OK',
        //                         'NONE',
        //                         'OK',
        //                         'KO',
        //                         'OK',
        //                         'OK',
        //                         'NONE',
        //                         'OK',
        //                         'KO',
        //                         'OK',
        //                         'OK',
        //                         'NONE',
        //                         'OK',
        //                         'KO',
        //                         'OK',
        //                         'OK',
        //                         'NONE',
        //                         'OK',
        //                         'KO',
        //                         'OK',
        //                         'OK',
        //                     ],
        //                 },
        //                 {
        //                     name: 'Account 2',
        //                     states: [
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                         'OK',
        //                     ],
        //                 },
        //             ],
        //         },
        //         {
        //             name: 'Bookmaker 2',
        //             accounts: [
        //                 {
        //                     name: 'Account 3',
        //                     states: [
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                         'KO',
        //                     ],
        //                 },
        //                 {
        //                     name: 'Account 4',
        //                     states: [
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                         'NONE',
        //                     ],
        //                 },
        //             ],
        //         },
        //     ],
        // } as ScrapingDashboardRunsMonitoringResponseDto);

        return this.http.get<ScrapingDashboardRunsMonitoringResponseDto>(
            `${this.baseUrl}/dashboard/runs-monitoring`,
            {
                params: dtoToHttpParams(dto),
            },
        );
    }

    getRuns(
        page: number,
        limit: number,
        dto: ScrapingRunListDto,
    ): Observable<Pagination<ScrapingRunResponseDto>> {
        return this.http.get<Pagination<ScrapingRunResponseDto>>(
            `${this.baseUrl}/runs/list/${page}/${limit}`,
            {
                params: dtoToHttpParams(dto),
            },
        );
    }

    getRunDetail(id: number): Observable<ScrapingRunDetailResponseDto> {
        return this.http.get<ScrapingRunDetailResponseDto>(`${this.baseUrl}/runs/detail/${id}`);
    }

    getInstancesBookmakerAccountIds(): Observable<ScrapingInstanceBookmakerAccountIds> {
        return this.http.get<ScrapingInstanceBookmakerAccountIds>(
            `${this.baseUrl}/instances/bookmaker-account-ids`,
        );
    }
}
