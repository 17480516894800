<div class="Header">
    <img src="assets/images/logo-moveup.jpg" alt="logo" class="Header-logo" />
    <div class="Header-spacer"></div>
    <div class="Header-user" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click" *ngIf="user">
        <nz-avatar [nzText]="userInitials" nzSize="large"></nz-avatar>
        <div class="Header-user-text">{{ user.firstName }} {{ user.lastName }}</div>
        <div class="Header-user-arrow">
            <span nz-icon nzType="caret-down" nzTheme="outline"></span>
        </div>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="editMyProfile()">Edit my profile</li>
            <li nz-menu-item (click)="logout()" nzDanger>Logout</li>
        </ul>
    </nz-dropdown-menu>
</div>
