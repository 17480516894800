import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditRoleDto,
    PermissionResponseDto,
    RoleNameExistsDto,
    RoleResponseDto,
} from 'src/app/core/services/moveup-api/users/roles/roles.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class RolesService {
    baseUrl = `${environment.api.baseUrl}/admin/users/roles`;

    constructor(private http: HttpClient) {}

    gerPermissionsList(): Observable<PermissionResponseDto[]> {
        return this.http.get<PermissionResponseDto[]>(`${this.baseUrl}/permissions`);
    }

    getList(): Observable<RoleResponseDto[]> {
        return this.http.get<RoleResponseDto[]>(`${this.baseUrl}/list`);
    }

    add(dto: AddEditRoleDto): Observable<RoleResponseDto> {
        return this.http.post<RoleResponseDto>(`${this.baseUrl}/add`, dto);
    }

    edit(id: string, dto: AddEditRoleDto): Observable<RoleResponseDto> {
        return this.http.put<RoleResponseDto>(`${this.baseUrl}/edit/${id}`, dto);
    }

    remove(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/remove/${id}`);
    }

    getRole(id: string): Observable<RoleResponseDto> {
        return this.http.get<RoleResponseDto>(`${this.baseUrl}/${id}`);
    }

    roleNameExists(dto: RoleNameExistsDto): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/name-exists`, dto);
    }
}
