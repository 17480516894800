<nz-form-item>
    <ng-content></ng-content>
    <nz-form-control
        nzSpan="24"
        *ngIf="formControl"
        [nzAutoTips]="globals.FORM_AUTO_TIPS"
        [nzErrorTip]="errorTip"
        [nzValidateStatus]="formControl"
        [nzExtra]="info"
        [nzHasFeedback]="hasFeedback"
    >
        <nz-space nzAlign="center">
            <nz-color-picker
                *nzSpaceItem
                nzSize="large"
                nzFormat="hex"
                [nzValue]="value"
                [nzDefaultValue]="'transparent'"
                (nzOnChange)="setValue($event)"
            ></nz-color-picker>
            <button
                *nzSpaceItem
                nz-button
                type="button"
                nzType="default"
                nzShape="circle"
                (click)="clear()"
            >
                <span nz-icon nzSize="small" nzType="close" nzTheme="outline"></span>
            </button>
        </nz-space>
    </nz-form-control>
</nz-form-item>
