import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { AffiliateAccountGuard } from 'src/app/core/guards/custom-guards/affiliate-account.guard';
import { LocalizationGuard } from 'src/app/core/guards/custom-guards/markets.guard';
import { ScrapingGuard } from 'src/app/core/guards/custom-guards/scraping.guard';
import { TrackingMaterialGuard } from 'src/app/core/guards/custom-guards/tracking-material.guard';
import { LayoutComponent } from 'src/app/layout/components/layout/layout.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            {
                path: 'home',
                loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('./features/users/users.module').then((m) => m.UsersModule),
                canActivate: [AdminGuard],
            },
            {
                path: 'accumulators',
                loadChildren: () =>
                    import('./features/accumulators/accumulators.module').then(
                        (m) => m.AccumulatorsModule,
                    ),
            },
            {
                path: 'games',
                loadChildren: () =>
                    import('./features/games/games.module').then((m) => m.GamesModule),
            },
            {
                path: 'bookmakers',
                loadChildren: () =>
                    import('./features/bookmakers/bookmakers.module').then(
                        (m) => m.BookmakersModule,
                    ),
                canActivate: [AdminGuard],
            },
            {
                path: 'websites',
                loadChildren: () =>
                    import('./features/websites/websites.module').then((m) => m.WebsitesModule),
                canActivate: [AdminGuard],
            },
            {
                path: 'competitors',
                loadChildren: () =>
                    import('./features/competitors/competitors.module').then(
                        (m) => m.CompetitorsModule,
                    ),
                canActivate: [AdminGuard],
            },
            {
                path: 'serp',
                loadChildren: () => import('./features/serp/serp.module').then((m) => m.SerpModule),
                canActivate: [AdminGuard],
            },
            {
                path: 'crud-example',
                loadChildren: () =>
                    import('./features/crud-example/crud-example.module').then(
                        (m) => m.CrudExampleModule,
                    ),
            },
            {
                path: 'data-tables',
                loadChildren: () =>
                    import('./features/data-tables/data-tables.module').then(
                        (m) => m.DataTablesModule,
                    ),
                canActivate: [AdminGuard],
            },
            {
                path: 'tracking-material',
                loadChildren: () =>
                    import('./features/tracking-material/tracking-material.module').then(
                        (m) => m.TrackingMaterialModule,
                    ),
                canActivate: [TrackingMaterialGuard],
            },
            {
                path: 'scraping',
                loadChildren: () =>
                    import('./features/scraping/scraping.module').then((m) => m.ScrapingModule),
                canActivate: [ScrapingGuard],
            },
            {
                path: 'affiliate-accounts',
                loadChildren: () =>
                    import('./features/affiliate-accounts/affiliate-accounts.module').then(
                        (m) => m.AffiliateAccountsModule,
                    ),
                canActivate: [AffiliateAccountGuard],
            },
            {
                path: 'cta-table-groups',
                loadChildren: () =>
                    import('./features/cta-table-groups/cta-table-groups.module').then(
                        (m) => m.CtaTableGroupsModule,
                    ),
                canActivate: [AdminGuard],
            },
            {
                path: 'localizations',
                loadChildren: () =>
                    import('./features/localizations/localizations.module').then(
                        (m) => m.LocaliaztionsModule,
                    ),
                canActivate: [LocalizationGuard],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
