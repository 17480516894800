import { createAction, props } from '@ngrx/store';
import { UserResponseDto } from 'src/app/core/services/moveup-api/users/users/users.dtos';

const domain = '[AUTH]';

const login = createAction(
    `${domain} Login`,
    props<{ user: UserResponseDto; accessToken: string; refreshToken: string }>(),
);

const updateUser = createAction(`${domain} Update User`, props<{ user: UserResponseDto }>());

const logout = createAction(`${domain} Logout`);

const impersonate = createAction(`${domain} Impersonate User`, props<{ user: UserResponseDto }>());
const switchBack = createAction(`${domain} Switch Back`);

export const AuthActions = {
    login,
    updateUser,
    logout,
    impersonate,
    switchBack,
};
