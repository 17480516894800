import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonPartnerResponseDto } from 'src/app/core/services/moveup-api/common/partner/partner.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class CommonPartnerService {
    baseUrl = `${environment.api.baseUrl}/admin/common/partner`;

    constructor(private http: HttpClient) {}

    getList(): Observable<CommonPartnerResponseDto[]> {
        return this.http.get<CommonPartnerResponseDto[]>(`${this.baseUrl}/list`);
    }
}
