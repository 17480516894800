import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SportType } from 'src/app/core/services/moveup-api';
import {
    LastUsedLeagueResponseDto,
    LeagueResponseDto,
} from 'src/app/core/services/moveup-api/common/league/league.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class LeagueService {
    baseUrl = `${environment.api.baseUrl}/admin/common/league`;

    constructor(private http: HttpClient) {}

    getList(sport: SportType, countryCode: string): Observable<LeagueResponseDto[]> {
        return this.http.get<LeagueResponseDto[]>(`${this.baseUrl}/list/${sport}/${countryCode}`);
    }

    getLastUsedLeagues(): Observable<LastUsedLeagueResponseDto[]> {
        return this.http.get<LastUsedLeagueResponseDto[]>(`${this.baseUrl}/last-used`);
    }
}
