<app-layout-breakpoint *ngIf="showBreakpoints"></app-layout-breakpoint>

<nz-layout class="main-layout">
    <app-layout-impersonated-header *ngIf="isImpersonated"></app-layout-impersonated-header>
    <nz-header>
        <app-layout-header></app-layout-header>
    </nz-header>
    <nz-layout>
        <nz-sider nzWidth="200px" nzTheme="light">
            <app-layout-menu></app-layout-menu>
        </nz-sider>
        <nz-layout class="inner-layout">
            <router-outlet></router-outlet>
        </nz-layout>
    </nz-layout>
</nz-layout>
