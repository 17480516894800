import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    set<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    get<T>(key: string): T | null {
        const value = localStorage.getItem(key);
        if (value) {
            return JSON.parse(value) as T;
        } else {
            return null;
        }
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }
}
