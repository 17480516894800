import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SportType } from 'src/app/core/services/moveup-api';
import { CountryResponseDto } from 'src/app/core/services/moveup-api/common/country/country.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class CountryService {
    baseUrl = `${environment.api.baseUrl}/admin/common/country`;

    constructor(private http: HttpClient) {}

    getList(sport: SportType): Observable<CountryResponseDto[]> {
        return this.http.get<CountryResponseDto[]>(`${this.baseUrl}/list/${sport}`);
    }
}
