import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from 'src/app/store/auth/auth.state';

const selectAuth = createFeatureSelector<AuthState>('auth');

const selectUser = createSelector(selectAuth, (state: AuthState) => state.user);
const selectIsAuthenticated = createSelector(
    selectAuth,
    (state: AuthState) => state.isAuthenticated,
);
const selectAccessToken = createSelector(selectAuth, (state: AuthState) => state.accessToken);
const selectRefreshToken = createSelector(selectAuth, (state: AuthState) => state.refreshToken);
const selectImpersonator = createSelector(selectAuth, (state: AuthState) => state.impersonator);
const selectImpersonatedUser = createSelector(selectAuth, (state: AuthState) =>
    state.impersonator ? state.user : null,
);

export const AuthSelectors = {
    selectAuth,
    selectUser,
    selectIsAuthenticated,
    selectAccessToken,
    selectRefreshToken,
    selectImpersonator,
    selectImpersonatedUser,
};
