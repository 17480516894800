<nz-form-item>
    <ng-content></ng-content>
    <nz-form-control
        *ngIf="formControl"
        [nzAutoTips]="globals.FORM_AUTO_TIPS"
        [nzErrorTip]="errorTip"
        [nzValidateStatus]="formControl"
        [nzExtra]="info"
        [nzHasFeedback]="hasFeedback"
        [nzValidatingTip]="validatingTip"
        nz-tooltip
        [nzTooltipTitle]="tooltip"
        [nzTooltipPlacement]="tooltipPlacement"
    >
        <input
            *ngIf="type !== 'number'"
            nz-input
            [type]="type"
            [nzSize]="size"
            [formControl]="formControl"
            [placeholder]="placeholder"
        />
        <nz-input-number
            *ngIf="type === 'number'"
            style="width: 100%"
            [nzSize]="size"
            [formControl]="formControl"
            [nzPlaceHolder]="placeholder"
        ></nz-input-number>
    </nz-form-control>
</nz-form-item>
