import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditBookmakerAccountDto,
    BookmakerAccountCredentialsUsernameExistsDto,
    BookmakerAccountFiltersDto,
    BookmakerAccountResponseDto,
} from 'src/app/core/services/moveup-api/affiliate-account/account/account.dtos';
import {
    ScraperInstanceDetailsResponseDto,
    ScrapingRunDetailResponseDto,
    ScrapingRunResponseDto,
} from 'src/app/core/services/moveup-api/scraping/scraping.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParams } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class AccountService {
    baseUrl = `${environment.api.baseUrl}/admin/affiliate-account/account`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: BookmakerAccountFiltersDto,
    ): Observable<Pagination<BookmakerAccountResponseDto>> {
        return this.http.get<Pagination<BookmakerAccountResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
            { params: dtoToHttpParams(dto) },
        );
    }

    export(dto: BookmakerAccountFiltersDto): Observable<BookmakerAccountResponseDto[]> {
        return this.http.get<BookmakerAccountResponseDto[]>(`${this.baseUrl}/export`, {
            params: dtoToHttpParams(dto),
        });
    }

    add(dto: AddEditBookmakerAccountDto): Observable<BookmakerAccountResponseDto> {
        return this.http.post<BookmakerAccountResponseDto>(`${this.baseUrl}/add`, dto);
    }

    edit(id: string, dto: AddEditBookmakerAccountDto): Observable<BookmakerAccountResponseDto> {
        return this.http.put<BookmakerAccountResponseDto>(`${this.baseUrl}/edit/${id}`, dto);
    }

    credentialsUsernameExists(
        dto: BookmakerAccountCredentialsUsernameExistsDto,
    ): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/credentials-username-exists`, dto);
    }

    getScrapingRuns(
        bookmakerAccountId: string,
        page: number,
        limit: number,
    ): Observable<Pagination<ScrapingRunResponseDto>> {
        return this.http.get<Pagination<ScrapingRunResponseDto>>(
            `${this.baseUrl}/scraping-runs/${bookmakerAccountId}/${page}/${limit}`,
        );
    }

    testScrapingRun(bookmakerAccountId: string): Observable<ScrapingRunDetailResponseDto> {
        return this.http.get<ScrapingRunDetailResponseDto>(
            `${this.baseUrl}/test-scraping-run/${bookmakerAccountId}`,
        );
    }

    getInstanceDetails(bookmakerAccountId: string): Observable<ScraperInstanceDetailsResponseDto> {
        return this.http.get<ScraperInstanceDetailsResponseDto>(
            `${this.baseUrl}/scraping-instance/${bookmakerAccountId}`,
        );
    }
}
