<nz-form-item>
    <ng-content></ng-content>
    <nz-form-control
        *ngIf="formControl"
        [nzAutoTips]="globals.FORM_AUTO_TIPS"
        [nzValidateStatus]="formControl"
        [nzExtra]="info"
        [nzErrorTip]="errorTip"
    >
        <nz-radio-group
            [formControl]="formControl"
            [nzSize]="buttonSize"
            [nzButtonStyle]="buttonStyle"
        >
            <ng-container *ngIf="type === 'radio'">
                <label *ngFor="let option of options" nz-radio [nzValue]="option.value">
                    {{ option.label }}
                </label>
            </ng-container>
            <ng-container *ngIf="type === 'button'">
                <label *ngFor="let option of options" nz-radio-button [nzValue]="option.value">
                    {{ option.label }}
                </label>
            </ng-container>
        </nz-radio-group>
    </nz-form-control>
</nz-form-item>
