import { Injectable } from '@angular/core';
import { mobileMode$ } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class Globals {
    DEFAULT_MARGIN = 23;
    HOME_METEO_WIDTH = 350;
    SELECT_MODAL_BREAKPOINT = 767;
    SHOW_FORM_DUMP = environment.debug?.showFormDump;
    FORM_FIELD_SPACE = this.DEFAULT_MARGIN / 2;
    APP_TITLE = 'MoveUp Intra';
    FORM_AUTO_TIPS: Record<string, Record<string, string>> = {
        default: {
            required: 'This field is required',
            email: 'The input is not valid email address',
        },
    };

    mobileMode = false;

    constructor() {
        mobileMode$.subscribe((mobileMode) => {
            this.mobileMode = mobileMode;
            this.DEFAULT_MARGIN = mobileMode ? 15 : 23;
        });
    }
}
