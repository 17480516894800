import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BookmakerInformationsDto } from 'src/app/core/services/moveup-api/bookmaker/bookmaker/bookmaker.dtos';
import {
    AddBookmakerWebsiteDto,
    AddEditWebsiteDto,
    BookmakerWebsiteResponseDto,
    WebsiteDetailResponseDto,
    WebsiteResponseDto,
    WebsitesFiltersFormDto,
} from 'src/app/core/services/moveup-api/website/website.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParams } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class WebsiteService {
    baseUrl = `${environment.api.baseUrl}/admin/website`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: WebsitesFiltersFormDto,
    ): Observable<Pagination<WebsiteResponseDto>> {
        return this.http.get<Pagination<WebsiteResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
            { params: dtoToHttpParams(dto) },
        );
    }

    getFormInfos(id: string): Observable<AddEditWebsiteDto> {
        return this.http.get<AddEditWebsiteDto>(`${this.baseUrl}/form-infos/${id}`);
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`);
    }

    addWebsite(dto: AddEditWebsiteDto): Observable<WebsiteResponseDto> {
        return this.http.post<WebsiteResponseDto>(`${this.baseUrl}`, dto);
    }

    editWebsite(id: string, dto: AddEditWebsiteDto): Observable<WebsiteResponseDto> {
        return this.http.put<WebsiteResponseDto>(`${this.baseUrl}/${id}`, dto);
    }

    getWebsiteDetail(id: string): Observable<WebsiteDetailResponseDto> {
        return this.http.get<WebsiteDetailResponseDto>(`${this.baseUrl}/detail/${id}`);
    }

    addBookmakerWebsite(websiteId: string, dto: AddBookmakerWebsiteDto): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/bookmaker/${websiteId}`, dto);
    }

    getBookmakerWebsite(bookmakerWebsiteId: string): Observable<BookmakerWebsiteResponseDto> {
        return this.http.get<BookmakerWebsiteResponseDto>(
            `${this.baseUrl}/bookmaker-website/${bookmakerWebsiteId}`,
        );
    }

    editBookmakerWebsite(
        bookmakerWebsiteId: string,
        dto: BookmakerInformationsDto,
    ): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/bookmaker-website/${bookmakerWebsiteId}`, dto);
    }

    removeBookmakerWebsite(bookmakerWebsiteId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/bookmaker-website/${bookmakerWebsiteId}`);
    }

    addToFavorites(websiteId: string): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/add-to-favorites/${websiteId}`, null);
    }

    removeFromFavorites(websiteId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/remove-from-favorites/${websiteId}`);
    }
}
