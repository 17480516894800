/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable()
export class AdminGuard {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) {}

    canActivate(_route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authService.getUser().pipe(
            switchMap((user) => {
                if (user.type !== 'admin') {
                    void this.router.navigateByUrl('/');

                    return of(false);
                }

                return of(true);
            }),
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.canActivate(route);
    }
}
