import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddBookmakerMarketDto,
    AddEditBookmakerDto,
    BookmakerDetailResponseDto,
    BookmakerFiltersDto,
    BookmakerInformationsDto,
    BookmakerMarketResponseDto,
    BookmakerResponseDto,
    DeactivateBookmakerMarketDto,
} from 'src/app/core/services/moveup-api/bookmaker/bookmaker/bookmaker.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class BookmakerService {
    baseUrl = `${environment.api.baseUrl}/admin/bookmaker/bookmaker`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: BookmakerFiltersDto,
    ): Observable<Pagination<BookmakerResponseDto>> {
        return this.http.post<Pagination<BookmakerResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
            dto,
        );
    }

    getFormInfos(id: string): Observable<AddEditBookmakerDto> {
        return this.http.get<AddEditBookmakerDto>(`${this.baseUrl}/form-infos/${id}`);
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`);
    }

    addBookmaker(dto: AddEditBookmakerDto): Observable<BookmakerResponseDto> {
        return this.http.post<BookmakerResponseDto>(`${this.baseUrl}`, dto);
    }

    editBookmaker(id: string, dto: AddEditBookmakerDto): Observable<BookmakerResponseDto> {
        return this.http.put<BookmakerResponseDto>(`${this.baseUrl}/${id}`, dto);
    }

    getBookmakerDetail(id: string): Observable<BookmakerDetailResponseDto> {
        return this.http.get<BookmakerDetailResponseDto>(`${this.baseUrl}/detail/${id}`);
    }

    addBookmakerMarket(bookmakerId: string, dto: AddBookmakerMarketDto): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/market/${bookmakerId}`, dto);
    }

    getBookmakerMarket(bookmakerMarketId: string): Observable<BookmakerMarketResponseDto> {
        return this.http.get<BookmakerMarketResponseDto>(
            `${this.baseUrl}/bookmaker-market/${bookmakerMarketId}`,
        );
    }

    editBookmakerMarket(
        bookmakerMarketId: string,
        dto: BookmakerInformationsDto,
    ): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/bookmaker-market/${bookmakerMarketId}`, dto);
    }

    deactivateBookmakerMarket(
        bookmakerMarketId: string,
        dto: DeactivateBookmakerMarketDto,
    ): Observable<BookmakerMarketResponseDto> {
        return this.http.put<BookmakerMarketResponseDto>(
            `${this.baseUrl}/deactivate-bookmaker-market/${bookmakerMarketId}`,
            dto,
        );
    }

    removeBookmakerMarket(bookmakerMarketId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/bookmaker-market/${bookmakerMarketId}`);
    }
}
