import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContinentQueryDto } from 'src/app/core/services/moveup-api/localization/dtos/continent/continent-request.dtos';
import { ContinentResponseDto } from 'src/app/core/services/moveup-api/localization/dtos/continent/continent-response.dtos';
import { dtoToHttpParamsV2 } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class ContinentService {
    baseUrl = `${environment.api.baseUrl}/admin/localization/continents`;

    constructor(private http: HttpClient) {}

    list(dto?: ContinentQueryDto): Observable<ContinentResponseDto[]> {
        return this.http.get<ContinentResponseDto[]>(`${this.baseUrl}`, {
            ...(dto && { params: dtoToHttpParamsV2(dto) }),
        });
    }
}
