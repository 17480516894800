import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, filter, interval, take } from 'rxjs';
import { Globals } from 'src/app/core/services/globals';
import { documentReadyStateComplete$ } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
    player: AnimationPlayer;

    constructor(
        private animationBuilder: AnimationBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private globals: Globals,
    ) {}

    ngOnInit(): void {
        this._handleSplashScreen();
        this._handlePageTitle();
    }

    private _handleSplashScreen(): void {
        const splashScreen: HTMLElement | null = document.querySelector('.Splash');
        if (splashScreen) {
            if (!environment.splashScreen) {
                splashScreen.remove();

                return;
            }

            const splashScreenDuration = 1500;
            const animationDuration = 400;

            this.player = this.animationBuilder
                .build(animate(animationDuration, style({ opacity: 0 })))
                .create(splashScreen);

            combineLatest([
                documentReadyStateComplete$,
                interval(splashScreenDuration).pipe(take(1)),
            ]).subscribe(() => {
                this.player.play();

                setTimeout(() => {
                    splashScreen.remove();
                }, animationDuration);
            });
        }
    }

    private _handlePageTitle(): void {
        const appTitle = this.globals.APP_TITLE;
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.titleService.setTitle(appTitle);
            this._setPageTitle(appTitle, this.route);
        });
    }

    private _setPageTitle(appTitle: string, route: ActivatedRoute): void {
        if (route.snapshot.data['title']) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const title = `${route.snapshot.data['title']} - ${appTitle}`;
            this.titleService.setTitle(title);
        }
        if (route.firstChild) {
            return this._setPageTitle(appTitle, route.firstChild);
        }
    }

    async wait(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}
